import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  ref,
  toRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { CustomerHistoryFilterOption } from '@leon-hub/api-sdk';
import { CustomerHistoryFilterType, HistoryFilter } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import type { GqlApiBatchedSubRequestError } from '@leon-hub/api';

import { ModalSelector } from 'web/src/modules/core/enums';
import type {
  DateOrderFilter,
  HistoryListItem, RequiredCustomerHistoryRequest,
  HistoryItem,
} from 'web/src/modules/profile/submodules/customer-history/types';
import {
  ActiveOrderFilterEnum,
} from 'web/src/modules/profile/submodules/customer-history/types';
import { useCurrency } from 'web/src/modules/money/composables';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { DEFAULT_ORDER_FILTER } from 'web/src/modules/profile/submodules/customer-history/store/constants';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalHeight, ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';

import type { CustomerHistoryListRouteComponentProps } from '../types';

export interface CustomerHistoryListRouteComponentComposable {
  selectedHistoryFilter: Ref<HistoryFilter>;
  isCashOutModal: Ref<boolean>;
  isLeonShopEnabled: Ref<boolean>;
  isInitialLoader: Ref<boolean>;
  portalSelector: ModalSelector;
  historyFilterOptions: Ref<CustomerHistoryFilterOption[]>;
  historyItems: Ref<HistoryItem[]>;
  isLastPage: Ref<boolean>;
  currency: Ref<string>;
  isLazyLoader: Ref<boolean>;
  currentCashOutItem: Ref<HistoryListItem | null>;
  onCashOutConfirm: () => Promise<void>;
  handleBetCashOut: (item: HistoryListItem) => Promise<void>;
  handleHistoryItemClick: (item: HistoryListItem) => void;
  handleCancelWithdrawal: (item: HistoryListItem) => void;
  onLoadMore: () => Promise<void>;
  onTabClick: (tabId: HistoryFilter) => Promise<void>;
  closeCashOutModal: () => void;
  onFilterChange: (option: Optional<CustomerHistoryFilterOption>) => Promise<void>;
  beforeMount: () => Promise<void>;
  beforeUnmount: () => void;
}

// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function useCustomerHistoryListRouteComponent(props: CustomerHistoryListRouteComponentProps): CustomerHistoryListRouteComponentComposable {
  const { $translate } = useI18n();
  const { showDialog, closeDialog } = useDialogs();
  const router = useRouter();
  const route = useRoute();
  const { currency: currencyBase } = useCurrency();

  const scgStore = useSiteConfigStore();
  const cashoutStore = useCashoutStore();
  const pendingBetsStore = usePendingBetsStore();
  const customerHistoryStore = useCustomerHistoryStore();
  const customerDataStore = useCustomerDataStore();
  const loyaltyShopStore = useLoyaltyShopStore();

  const offers = toRef(loyaltyShopStore, 'offers');

  const historyItems = toRef(customerHistoryStore, 'historyItems');
  const isInitialLoader = toRef(customerHistoryStore, 'isInitialLoader');
  const isLastPage = toRef(customerHistoryStore, 'isLastPage');
  const isLazyLoader = toRef(customerHistoryStore, 'isLazyLoader');
  const selectedHistoryFilter = toRef(customerHistoryStore, 'selectedHistoryFilter');
  const isLeonShopHistoryExists = toRef(customerHistoryStore, 'isLeonShopHistoryExists');
  const historyFilterOptions = toRef(customerDataStore, 'historyFilterOptions');
  const defaultHistoryFilterOption = toRef(customerDataStore, 'defaultHistoryFilterOption');
  const isLeonShopEnabledStore = toRef(scgStore, 'isLeonShopEnabled');

  const portalSelector = process.env.VUE_APP_LAYOUT_DESKTOP
    ? ModalSelector.DESKTOP_INNER_MODAL
    : ModalSelector.BODY;

  const isCashOutModal = ref(false);
  const currentCashOutItem = ref<HistoryListItem | null>(null);
  const modalId = ref<Maybe<string>>(null);

  const selectedFilter = computed<HistoryFilter>(() => {
    const filter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('filter')
      : route.query.filter;
    return Object.values(HistoryFilter).find((value) => value === filter) ?? DEFAULT_ORDER_FILTER.filter;
  });

  const isLeonShopFilter = computed<boolean>(() => selectedFilter.value === HistoryFilter.LEONSHOP);

  const currency = computed<string>(() => {
    if (isLeonShopFilter.value) {
      return 'L';
    }
    return currencyBase.value;
  });

  // better extend array on server
  const pendingBetOption = computed<CustomerHistoryFilterOption>(() => ({
    dateRange: 365,
    dateType: CustomerHistoryFilterType.DAY,
    isDefault: false,
    order: 0.1,
    title: $translate('WEB2_PENDING_BETS').value,
  }));

  const fullHistoryFilterOptions = computed<CustomerHistoryFilterOption[]>(() => {
    if (historyFilterOptions.value.length === 0) {
      return [];
    }
    return [pendingBetOption.value, ...historyFilterOptions.value];
  });

  const selectedHistoryFilterOption = computed<Optional<CustomerHistoryFilterOption>>(() => {
    const activeOrderFilter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('activeOrderFilter')
      : route.query.activeOrderFilter;
    return fullHistoryFilterOptions.value.find((value) => value.order === Number(activeOrderFilter));
  });

  const isPendingBets = computed<boolean>(() => {
    const filter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('filter')
      : route.query.filter;
    const activeOrderFilter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('activeOrderFilter')
      : route.query.activeOrderFilter;
    return ((filter === HistoryFilter.ALL_BETS || filter === HistoryFilter.PENDING_BETS)
      && activeOrderFilter === ActiveOrderFilterEnum.PENDING_BETS);
  });

  const selectedOrderFilter = computed<DateOrderFilter>(() => {
    const defaultOption = {
      dateRange: defaultHistoryFilterOption.value?.dateRange ?? DEFAULT_ORDER_FILTER.dateRange,
      dateType: defaultHistoryFilterOption.value?.dateType ?? DEFAULT_ORDER_FILTER.dateType,
    };
    if (isPendingBets.value) {
      return defaultOption;
    }
    return selectedHistoryFilterOption.value ?? defaultOption;
  });

  const queryParameters = computed<Record<string, string>>(() => {
    const defaultFilter = {
      filter: selectedFilter.value,
      activeOrderFilter: ActiveOrderFilterEnum.DEFAULT,
    };
    if (!selectedHistoryFilterOption.value) {
      return defaultFilter;
    }
    if (selectedHistoryFilterOption.value.order === Number(ActiveOrderFilterEnum.PENDING_BETS)
      && !isPendingBets.value) {
      return defaultFilter;
    }
    return {
      filter: selectedFilter.value,
      activeOrderFilter: String(selectedHistoryFilterOption.value.order),
    };
  });

  const requestParameters = computed<RequiredCustomerHistoryRequest>(() => {
    const parameters = {
      filter: selectedFilter.value,
      dateRange: selectedOrderFilter.value.dateRange,
      dateType: selectedOrderFilter.value.dateType,
      page: DEFAULT_ORDER_FILTER.page,
      rows: DEFAULT_ORDER_FILTER.rows,
    };
    if (isPendingBets.value) {
      return {
        ...parameters,
        filter: HistoryFilter.PENDING_BETS,
        dateRange: pendingBetOption.value.dateRange,
        dateType: pendingBetOption.value.dateType,
      };
    }
    return parameters;
  });

  function updateCustomerHistory(options: RequiredCustomerHistoryRequest): Promise<void> {
    return customerHistoryStore.updateCustomerHistoryAction(options);
  }

  async function updateHistory(): Promise<void> {
    if (props.filterParamsFromProps) {
      await updateCustomerHistory(props.filterParamsFromProps);
    } else {
      void router.replace({
        name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
        query: queryParameters.value,
      });
      await nextTick();
      await updateCustomerHistory(requestParameters.value);
    }
  }

  async function onLoadMore(): Promise<void> {
    if (!isLastPage.value) {
      await updateCustomerHistory({
        ...requestParameters.value,
        page: customerHistoryStore.currentFilterState.page + 1,
      });
    }
  }

  function updateQuery(tabId: HistoryFilter): void {
    const { activeOrderFilter } = queryParameters.value;
    const isPendingBetsValue = (activeOrderFilter as ActiveOrderFilterEnum) === ActiveOrderFilterEnum.PENDING_BETS;
    void router.replace({
      name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
      query: {
        filter: tabId,
        activeOrderFilter: isPendingBetsValue ? ActiveOrderFilterEnum.DEFAULT : activeOrderFilter,
      },
    });
  }

  async function onTabClick(tabId: HistoryFilter): Promise<void> {
    if (tabId !== selectedHistoryFilter.value) {
      updateQuery(tabId);
      await nextTick();
      await updateCustomerHistory({
        ...requestParameters.value,
        filter: tabId,
      });
    }
  }

  async function onFilterChange(option: Optional<CustomerHistoryFilterOption>): Promise<void> {
    if (!option) {
      return;
    }

    const id = option.order;
    const stringId = `${id}` as ActiveOrderFilterEnum;

    const filter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('filter')
      : route.query.filter;

    void router.replace({
      name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
      query: { filter, activeOrderFilter: stringId },
    });

    const isPendingBetsValue = (selectedHistoryFilter.value === HistoryFilter.ALL_BETS)
      && (stringId === ActiveOrderFilterEnum.PENDING_BETS);

    await updateCustomerHistory({
      ...requestParameters.value,
      filter: isPendingBetsValue ? HistoryFilter.PENDING_BETS : selectedHistoryFilter.value,
      dateType: option.dateType,
      dateRange: option.dateRange,
    });
  }

  function onWithdrawalModalCancel(item: HistoryListItem): void {
    customerHistoryStore.doWithdrawalCancel({ paymentId: Number(item.objectId) })
      .then(updateHistory)
      .then(() => {
        const { id } = showDialog({
          presetName: PresetName.ALERT_SUCCESS,
          options: {
            height: ModalHeight.DEFAULT,
            fullHeight: true,
            isFullHeightCentered: false,
            title: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT').value,
            buttons: [
              {
                kind: ButtonKind.BASE,
                label: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT_CLOSE').value,
              },
            ],
          },
        });

        modalId.value = id;
      })
      .catch((error: GqlApiBatchedSubRequestError) => {
        const { id } = showDialog({
          presetName: PresetName.ALERT_ERROR,
          options: {
            confirmMessage: error.message,
            height: ModalHeight.SMALL_MINUS,
          },
        });

        modalId.value = id;
        void updateHistory();
      });
  }

  function handleHistoryItemClick(item: HistoryListItem): void {
    const {
      objectId,
      objectType,
      accountType,
      cardOrder,
    } = item;
    if (objectId && objectType && !cardOrder) {
      void router.push({
        name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
        params: {
          id: `${objectId}`,
          group: `${objectType.toLowerCase()}`,
          accountType: `${accountType.toLowerCase()}`,
        },
      });
    }
  }

  function handleCancelWithdrawal(item: HistoryListItem): void {
    const { subscribe, id } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT').value,
        confirmMessage: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_QUESTION').value,
        width: ModalWidth.SMALL,
        isCentered: false,
        buttons: [
          {
            label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_YES').value,
          },
          {
            label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_NO').value,
          },
        ],
      },
    });

    modalId.value = id;

    subscribe({
      [DialogAction.CONFIRM]: () => {
        onWithdrawalModalCancel(item);
      },
    });
  }

  function closeCashOutModal(): void {
    isCashOutModal.value = false;
    currentCashOutItem.value = null;
    cashoutStore.resetState();
  }

  async function onCashOutConfirm(): Promise<void> {
    await onCashOutSuccess();
    closeCashOutModal();
  }

  async function onCashOutSuccess(): Promise<void> {
    if (currentCashOutItem.value) {
      await Promise.all([
        customerHistoryStore.updateHistoryListItem({
          id: currentCashOutItem.value.objectId,
          group: currentCashOutItem.value.objectType,
          accountType: currentCashOutItem.value.accountType,
        }),
        pendingBetsStore.loadPendingBets(),
      ]);
    }
  }

  async function handleBetCashOut(item: HistoryListItem): Promise<void> {
    isCashOutModal.value = true;
    currentCashOutItem.value = item;
    if (item.objectId) {
      await cashoutStore.initCashout({
        betId: Number(item.objectId),
        betType: 'betType' in item ? item.betType : null,
      });
    }
  }

  const isLeonShopEnabled = computed<boolean>(
    () => (isLeonShopEnabledStore.value && offers.value.length > 0) || isLeonShopHistoryExists.value,
  );

  async function beforeMount() {
    await updateHistory();
    await customerHistoryStore.checkIfLeonShopHistoryExists();
    if (isLeonShopEnabledStore.value || offers.value.length > 0) {
      await loyaltyShopStore.fetchCustomerOffers();
    }
  }

  function beforeUnmount(): void {
    if (modalId.value) {
      closeDialog(modalId.value);
    }
    customerHistoryStore.onDestroyCustomerHistory();
  }

  return {
    selectedHistoryFilter,
    isCashOutModal,
    isLeonShopEnabled,
    isInitialLoader,
    portalSelector,
    historyFilterOptions,
    historyItems,
    isLastPage,
    currency,
    isLazyLoader,
    currentCashOutItem,
    onCashOutConfirm,
    handleBetCashOut,
    handleHistoryItemClick,
    handleCancelWithdrawal,
    onLoadMore,
    onTabClick,
    closeCashOutModal,
    onFilterChange,
    beforeMount,
    beforeUnmount,
  };
}
