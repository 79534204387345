import type { Ref } from 'vue';
import {
  toRef, computed, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { OpenIn } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';
import { makeDeposit, makeWithdrawal } from '@leon-hub/cordova';
import { RouteName } from '@leon-hub/routing-config-names';
import { assert, isNullOrUndefined } from '@leon-hub/guards';
import { useWindowVisibilityChanged } from '@leon-hub/browser-composables';

import { getContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import PaymentsPostMessageEvent from 'web/src/modules/payments/utils/PaymentsPostMessageEvent';

import type { PaymentsIframeProps } from '../types';

export interface PaymentsIframeComposable {
  iframeName: string;
  showIframe: Ref<boolean>;
  iframeMounted: () => void;
  mounted: () => void;
  beforeUnmount: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsIframe(props: PaymentsIframeProps): PaymentsIframeComposable {
  const router = useRouter();

  const FORM_NAME = 'paymentsRedirectForm';
  const iframeName = 'payments-depositok';
  const postMessageBus = ref<PostMessageBus | null>(null);

  const color = process.env.VUE_APP_LAYOUT_PHONE ? '#fff' : '#000';

  const cssLoader = `
    .lds-dual-ring {
      margin: 30px auto 0;
      width: 30px;
      height: 30px;
    }
    .lds-dual-ring:after {
      opacity: 0.5;
      content: " ";
      display: block;
      width: 24px;
      height: 24px;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid ${color};
      border-color: ${color} transparent ${color} transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  const openInIframe = toRef(() => props.openIn === OpenIn.IFRAME);

  const form = computed<HTMLElement>(() => {
    const element = document.createElement('form');
    element.setAttribute('accept-charset', props.redirectCharset ?? '');
    element.setAttribute('action', props.redirectUrl ?? '');
    element.setAttribute('method', props.redirectMethod ?? 'POST');
    element.setAttribute('name', FORM_NAME);

    if (props.redirectFormParams) {
      const keys = Object.keys(props.redirectFormParams);
      for (const key of keys) {
        const input = document.createElement('input');
        const value = props.redirectFormParams ? props.redirectFormParams[key] : '';
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', `${key}`);
        input.setAttribute('value', `${value}`);
        element.append(input);
      }
    }

    return element;
  });

  const showIframe = computed<boolean>(() => !!(openInIframe.value && !props.redirectFormParams && props.redirectUrl));

  const windowOpenParams = toRef(() => {
    if (props.openIn === OpenIn.NEW_WINDOW && process.env.VUE_APP_LAYOUT_DESKTOP) {
      return 'width=800px,height=600px,toolbar=no,titlebar=no,status=no,menubar=no';
    }
    return '';
  });

  const windowOpenTarget = toRef(() => {
    if (props.openIn === OpenIn.NEW_WINDOW && !process.env.VUE_APP_BROWSER_SAFARI) {
      return '_blank';
    }
    return '_self';
  });

  const redirectHtmlDocument = computed<string>(() => `<style>${cssLoader}</style>
            <div id="redirectForm">
              <div class="lds-dual-ring"></div>
              ${form.value.outerHTML}
            </div>`);

  const canRedirect = computed<boolean>(() => !!(props.redirectUrl && props.redirectFormParams === null));

  function processRedirectForm(): void {
    try {
      const newWindow = window.open('', windowOpenTarget.value, windowOpenParams.value);

      if (newWindow) {
        newWindow.document.body.innerHTML = redirectHtmlDocument.value;

        setTimeout(() => {
          // @ts-ignore
          newWindow.document.forms[FORM_NAME].submit();
        }, 100);
      }

      doRedirect();
    } catch (error) {
      logger.error(`Can't open a new window for redirect form: ${error}`);
    }
  }

  function redirectDepositsOrWithdrawal(): void {
    if (!props.isDeposit) {
      void router.push({ name: RouteName.WITHDRAWALS });
    }
  }

  function doRedirect(): void {
    if (props.openIn !== OpenIn.NEW_WINDOW) {
      redirectDepositsOrWithdrawal();
    } else if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      void router.closeModal();
    } else if (props.mobileRedirectUrl) {
      void router.push(props.mobileRedirectUrl || '/');
    } else {
      redirectDepositsOrWithdrawal();
    }
  }

  function createPostMessage(): void {
    const contentWindow = getContentWindowByIFrameName(iframeName);
    assert(!isNullOrUndefined(contentWindow), 'Iframe contentWindow doesn\'t exist on mounted');

    postMessageBus.value = new PostMessageBus({
      target: contentWindow,
      targetOrigin: '*',
      initiator: 'PaymentsIframe',
    });
  }

  function iframeMounted(): void {
    createPostMessage();
  }

  function listenPostMessage(): void {
    if (postMessageBus.value) {
      // If the close button was clicked in iframe
      postMessageBus.value.on(PaymentsPostMessageEvent.depositok, (payload: { status: boolean }) => {
        if (payload.status) {
          if (process.env.VUE_APP_LAYOUT_PHONE) {
            void router.push(props.mobileRedirectUrl || '/');
          } else {
            void router.closeModal();
          }
        }
      });
    }
  }

  function makeCordovaPayment(): void {
    if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
      return;
    }

    try {
      const isNeedRedirectHtmlDocument = !(openInIframe.value && !props.redirectFormParams) && !canRedirect.value;

      if (props.isDeposit) {
        void makeDeposit({
          url: props.redirectUrl || '',
          onSuccess: () => {
            void router.push({ name: RouteName.DEPOSITSOK });
          },
          onError: (error) => {
            logger.warn('Failed to open cordova deposit', error);
            void router.push({ name: RouteName.DEPOSITSFAIL });
          },
          onProcess: () => {
            void router.push({ name: RouteName.DEPOSITSPROCESS });
          },
          onClose: () => {
            void router.push({ name: RouteName.DEPOSITS });
          },
          redirectHtmlDocument: isNeedRedirectHtmlDocument ? redirectHtmlDocument.value : '',
          isDeepLinksAllowed: !!props.isDeepLinksAllowed,
        });
      } else {
        void makeWithdrawal({
          url: props.redirectUrl || '',
          onSuccess: () => {
            void router.push({ name: RouteName.WITHDRAWALOK });
          },
          onError: () => {
            void router.push({ name: RouteName.WITHDRAWALS });
          },
          onClose: () => {
            void router.push({ name: RouteName.WITHDRAWALS });
          },
          redirectHtmlDocument: isNeedRedirectHtmlDocument ? redirectHtmlDocument.value : '',
        });
      }
    } catch (error) {
      logger.warn('Failed to open cordova payment', error);
      void router.push({
        name: props.isDeposit ? RouteName.DEPOSITSFAIL : RouteName.WITHDRAWALS,
      });
    }
  }

  function handleVisibilityChange(): void {
    if (document.hidden && props.openIn === OpenIn.NEW_WINDOW && process.env.VUE_APP_LAYOUT_PHONE) {
      doRedirect();
    }
  }

  function mounted(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      makeCordovaPayment();
      // Opens content in Iframe
    } else if (!openInIframe.value && props.redirectFormParams) {
      processRedirectForm();
    } else if (!openInIframe.value && !props.redirectFormParams) {
      if (canRedirect.value) {
        try {
          window.open(props.redirectUrl || '', windowOpenTarget.value, windowOpenParams.value);
          if (props.openIn === OpenIn.NEW_WINDOW) {
            doRedirect();
          }
        } catch (error) {
          logger.error(`Can't open in a new window: ${error}`);
        }
        // Creates a form with params and then sends it
      } else {
        processRedirectForm();
      }
    }
  }

  useWindowVisibilityChanged(handleVisibilityChange);

  function beforeUnmount(): void {
    postMessageBus.value?.dispose();
  }

  watch(postMessageBus, (newValue) => {
    if (newValue && openInIframe.value && !props.redirectFormParams) {
      listenPostMessage();
    }
  }, { immediate: true });

  return {
    showIframe,
    iframeName,
    iframeMounted,
    mounted,
    beforeUnmount,
  };
}
