<script setup lang="ts">
import type { BaseInputEmits } from 'web/src/components/Input';
import { TextInput } from 'web/src/components/Input';
import { TextInputTypes } from 'web/src/components/Input/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';

import { useAutocompleteAddress } from './composables';
import type { AutocompleteAddressProps } from './types/AutocompleteAddressProps';

const props = withDefaults(defineProps<AutocompleteAddressProps>(), {
  name: 'address',
});

const emits = defineEmits<BaseInputEmits>();

const {
  inputValue,
  predictions,
  onInput,
  onChange,
  onClear,
  onBlur,
  onFocus,
  showPredictions,
  selectPrediction,
  preselectedIndex,
  isEmptyResultBlockShown,
  isPendingSearch,
  onKeyDown,
  setPreselectedIndex,
} = useAutocompleteAddress(props, emits);
</script>

<template>
  <fieldset v-auto-id="'AutocompleteAddress'" :class="$style.address">
    <TextInput
      :autofocus="autofocus"
      :value="inputValue"
      :label="label"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :hint="hint"
      :hint-right="hintRight"
      :error="error"
      :error-right="errorRight"
      :hint-link="hintLink"
      :hint-link-right="hintLinkRight"
      :type="TextInputTypes.TEXT"
      :autocomplete="autocomplete"
      :regexp="regexp"
      @keydown="onKeyDown"
      @input="onInput"
      @change="onChange"
      @clear="onClear"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template
        v-if="showPredictions"
        #dropdown
      >
        <div
          :class="$style.address__predictions"
        >
          <div
            v-if="predictions.length && disallowUserInput"
            :class="$style.address__caption"
          >
            {{ $t('WEB2_AUTOCOMPLETE_ADDRESS_SELECT_CITY') }}
          </div>
          <div
            v-if="isEmptyResultBlockShown"
            :class="$style['address__nothing-found']"
          >
            <template v-if="isPendingSearch">
              <VLoaderDelayed :delay="1500" />
            </template>
            <template v-else>
              {{ inputValue ? $t('WEB2_AUTOCOMPLETE_ADDRESS_NOT_FOUND') : $t('WEB2_AUTOCOMPLETE_ADDRESS_NEED_TO_SELECT_CITY') }}
            </template>
          </div>
          <button
            v-for="(prediction, index) in predictions"
            :key="prediction.place_id"
            :class="{
              [$style['address__predictions-item']]: true,
              [$style['address__predictions-item--preselected']]: preselectedIndex === index,
            }"
            type="button"
            @mousedown="selectPrediction(prediction)"
            @mouseenter="setPreselectedIndex(index)"
          >
            {{ prediction.description }}
          </button>
        </div>
      </template>
    </TextInput>
  </fieldset>
</template>

<style lang="scss" module>
.address {
  padding: 0;
  margin: 0;
  border: none;

  &__predictions {
    @include z-index(absolute-item);

    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: -14px 0 0;
    background-color: var(--Layer0);
  }

  &__caption,
  &__nothing-found {
    color: var(--TextSecondary);
  }

  &__caption {
    @include medium\13\16;

    padding: 16px 8px 12px;
  }

  &__nothing-found {
    @include regular\14\24\025;

    padding: 16px 12px;
    text-align: center;
  }

  &__predictions-item {
    @include regular\14\20\025;

    display: block;
    width: 100%;
    padding: 12px;
    margin: 4px 0 0;
    color: var(--TextPrimary);
    text-align: left;
    cursor: pointer;
    background: var(--Layer1);
    border: none;
    border-radius: 5px;

    &--preselected {
      background: var(--Highlight);
    }
  }
}
</style>
