import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { ModalWidth } from 'web/src/components/Modal/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCommonAnalyticsData } from 'web/src/modules/analytics/composables/useCommonAnalyticsData';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';

export interface UseWithdrawalCancelComposable {
  handleCancelWithdrawal(): void;
  sendAnalyticsWhenPopupSetVisible(): void;
}

export function useWithdrawalCancel(): UseWithdrawalCancelComposable {
  const withdrawalStore = useWithdrawalStore();
  const customerHistoryStore = useCustomerHistoryStore();
  const paymentsStore = usePaymentsStore();
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();
  const router = useRouter();
  const { commonLogData } = useCommonAnalyticsData();
  const analytics = useAnalytics(commonLogData);
  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();

  const withdrawalAmount = ref<number>();
  const routeName = computed(() => router.currentRoute.value.name);
  const requestedCount = toRef(() => paymentsStore.requestedCount);
  const paymentId = toRef(() => withdrawalStore.latestPaymentId);
  const amountMoney = toRef(() => withdrawalStore.submittedAmountAsMoney);

  const withdrawalsAnalyticsDataByClick = computed(() => getYMAnalyticsData(true));
  const withdrawalsAnalyticsDataForVisible = computed(() => getYMAnalyticsData(false));

  function getYMAnalyticsData(byClick: boolean): Record<string, unknown> {
    const routeNameValue = String(routeName.value);
    const requestedWithdrawalsCount = requestedCount.value;
    const currencyValue = currency.value;
    const withdrawalAmountValue = withdrawalAmount.value;

    return {
      withdrawals: byClick
        ? {
            withdrawalCancellationClick: {
              location: {
                [routeNameValue]: {
                  withdrawals_count: {
                    [requestedWithdrawalsCount]: {
                      [currencyValue]: withdrawalAmountValue,
                    },
                  },
                },
              },
            },
          }
        : {
            withdrawalCancellationPopUp: {
              location: routeNameValue,
            },
          },
    };
  }

  function handleCancelWithdrawal(): void {
    const { subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT').value,
        confirmMessage: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_QUESTION').value,
        width: ModalWidth.SMALL,
        isCentered: false,
        buttons: [
          {
            label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_YES').value,
          },
          {
            label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_NO').value,
          },
        ],
      },
    });

    subscribe({
      [DialogAction.CONFIRM]: async () => {
        if (paymentId.value) {
          await cancelSingleWithdrawal(paymentId.value);
          void router.push({ name: RouteName.PROFILE });
        } else {
          await cancelAllWithdrawals();
        }
      },
    });
  }

  function showSuccessModal(totalCount?: number): void {
    showDialog({
      presetName: PresetName.ALERT_SUCCESS,
      options: {
        title: $translate('JSPNAV_USTAT_WITHDRAWAL_CANCEL_OK').value,
        confirmMessage: `${$translate('WEB2_WTHDRAWAL_SUCCESS_MODAL_DESCR').value} ${totalCount ? formatMoney(totalCount) : amountMoney.value}`,
        width: ModalWidth.SMALL,
        isCentered: false,
        buttons: [
          {
            label: $translate('WEB2_CLOSE_CUSTOMER_NOTIFICATION').value,
          },
        ],
      },
    });
  }

  function sendAnalyticsWhenPopupSetVisible(): void {
    analytics.push(AnalyticsEvent.Z_WITHDRAWAL_CANCELLATION_POPUP, withdrawalsAnalyticsDataForVisible.value);

    analytics.pushGTM(AnalyticsEvent.WITHDRAWAL_CANCELLATION_POPUP, {
      event: AnalyticsEvent.WITHDRAWAL_CANCELLATION_POPUP,
      event_action: 'Modal popup',
      event_label: 'Withdrawal success modal',
    });
  }

  function sendAnalyticsByClick(): void {
    analytics.push(AnalyticsEvent.Z_WITHDRAWAL_CANCELLATION_CLICK, withdrawalsAnalyticsDataByClick.value);

    analytics.pushGTM(AnalyticsEvent.WITHDRAWAL_CANCELLATION, {
      event: AnalyticsEvent.WITHDRAWAL_CANCELLATION,
      event_action: 'Request cancellation',
      event_label: 'Withdrawal success modal',
      withdrawals_count: requestedCount.value,
      withdrawals_value: withdrawalAmount.value,
      currency: currency.value,
    });
  }

  async function cancelAllWithdrawals(): Promise<void> {
    await withdrawalStore.cancelRequestedWithdrawals().then((response) => {
      if (response.totalCount) {
        void router.push({
          name: RouteName.PROFILE,
        });
        showSuccessModal(response.totalAmount);
        withdrawalAmount.value = response.totalAmount;
        sendAnalyticsByClick();
      }
    });
  }

  async function cancelSingleWithdrawal(paymentId: number): Promise<void> {
    await customerHistoryStore.doWithdrawalCancel({ paymentId }).then(() => {
      showSuccessModal();
      withdrawalStore.setPaymentId(0);
      sendAnalyticsByClick();
    });
  }

  return {
    handleCancelWithdrawal,
    sendAnalyticsWhenPopupSetVisible,
  };
}
