import { defineStore } from 'pinia';
import type { ComputedRef } from 'vue';
import { computed, ref, toRef } from 'vue';

import { assert, isString } from '@leon-hub/guards';
import type {
  GetPromotionsRequest,
  PromotionRequest,
  RatePromotionRequest,
  SetParticipationRequest,
  UpdatePromotionRequest,
} from '@leon-hub/api-sdk';
import {
  CategoryType,
  doButtonMutation,
  doPromotionParticipation,
  doPromotionRate,
  getBonusWalletPromotion,
  getPromotionDetails,
  getPromotions,
  PromotionActionButtonType,
} from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import type { NavigationItem } from '@leon-hub/navigation-config';
import { Timer } from '@leon-hub/utils';
import type { GqlApiResponseErrorCode } from '@leon-hub/api';

import type {
  BonusWalletPromotionDetailsDocument,
  PromotionActionButtonDataDocument,
  PromotionFullDetailsDocument,
  PromotionMergedDetailsDocument,
  PromotionPartialDetailsDocument,
  PromotionsByCategoryType,
  PromotionsCategoryType,
} from 'web/src/modules/promotions/types';
import { PromotionCustomerRatingMethodType } from 'web/src/modules/promotions/types';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useNavigationItemsStore, useNavigationStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { isCategoryType } from 'web/src/modules/promotions/utils';
import { isPromotion } from 'web/src/modules/promotions/utils/typesValidators';
import type { PromotionsListItemProps } from 'web/src/modules/promotions/components/PromotionsListItem/types';
import useIdentificationNeededModal from 'web/src/modules/dialogs/composables/useIdentificationNeededModal';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

const usePromotionsStore = defineStore('promotions', () => {
  const DEFAULT_POLLING_INTERVAL = 3000;

  const api = useGraphqlClient();
  const analytics = useAnalytics();
  const navigationStore = useNavigationStore();
  const siteConfigStore = useSiteConfigStore();
  const { showIdentificationNeededModal } = useIdentificationNeededModal();
  const errorConverter = useErrorsConverter();

  const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');

  let $pollingInterval = DEFAULT_POLLING_INTERVAL;
  const activeCategoryId = ref('');
  const openedActionUrl = ref('');
  const promotions = ref<Record<string, PromotionMergedDetailsDocument>>({});
  const bonusWallet = ref<BonusWalletPromotionDetailsDocument | null>(null);
  const participationTable = ref<Record<string, boolean>>({});
  const filters = ref<Record<string, string[]>>({});
  const promotionsLoaded = ref(false);
  const finishedPromotions = ref<string[]>([]);
  const archivedPromotions = ref<string[]>([]);
  const customerPromotions = ref<string[]>([]);
  const customerPromoNumbers = ref(0);
  const isRateButtonEnabled = ref(true);
  const promotionLikes = ref(0);

  const categoryIdByCategoryTypeCache: Record<string, ComputedRef<string>> = {};
  const customerCategoryId = 'customer';
  let $timeoutId = 0;

  // Mutations:
  function updatePromotionState(value: PromotionMergedDetailsDocument) {
    assert(
      value.actionUrl !== '' || typeof value.actionUrl !== 'undefined',
      'promotion.actionUrl should not be empty',
    );
    // TODO: refactoring promotion.actionUrl as a promotionId
    const promotionId = value.actionUrl || '';
    if (promotionId in promotions.value) {
      promotions.value[promotionId] = {
        ...promotions.value[promotionId],
        ...value,
      };
    } else {
      promotions.value[promotionId] = value;
    }
  }

  function setPromotions(value: PromotionPartialDetailsDocument[]): void {
    const filter: string[] = [];
    for (const promotion of value) {
      const promotionId = promotion.actionUrl || '';
      filter.push(promotionId);
      updatePromotionState(promotion);
    }

    promotionsLoaded.value = true;

    if (activeCategoryId.value) {
      filters.value[activeCategoryId.value] = filter;
    }
  }

  function setBonusWallet(bonusWalletValue: BonusWalletPromotionDetailsDocument): void {
    bonusWallet.value = bonusWalletValue;
  }

  function updatePromotion(promotion: PromotionFullDetailsDocument): void {
    updatePromotionState(promotion);
  }

  function setCustomerParticipation({
    actionUrl,
    isCustomerParticipating,
  }: {
    actionUrl: string;
    isCustomerParticipating: boolean;
  }): void {
    participationTable.value[actionUrl] = isCustomerParticipating;
  }

  function setActiveCategoryId(categoryId: string): void {
    activeCategoryId.value = categoryId;
  }

  function setOpenedActiveUrl(activeUrl = ''): void {
    openedActionUrl.value = activeUrl;
  }

  function setPollingInterval(value: number): void {
    $pollingInterval = value;
  }

  function updatePromotionButton(payload: {
    actionUrl: string;
    actionButton: PromotionActionButtonDataDocument;
  }): void {
    const { actionUrl, actionButton } = payload;
    if (promotions.value[actionUrl]) {
      promotions.value[actionUrl].actionButton = actionButton;
    }
  }

  function setPromotionsLoadedState(value: boolean): void {
    promotionsLoaded.value = value;
  }

  function setFinishedPromotions(value: PromotionPartialDetailsDocument[]): void {
    const finishedIds: string[] = [];
    for (const promotion of value) {
      const promotionId = promotion.actionUrl || '';
      finishedIds.push(promotionId);
      finishedPromotions.value = finishedIds;
      updatePromotionState(promotion);
    }
  }

  function setArchivedPromotions(value: PromotionPartialDetailsDocument[]): void {
    const archivedIds: string[] = [];
    for (const promotion of value) {
      const promotionId = promotion.actionUrl || '';
      archivedIds.push(promotionId);
      archivedPromotions.value = archivedIds;
      updatePromotionState(promotion);
    }
  }

  function setCustomerPromotions(value: PromotionPartialDetailsDocument[]): void {
    const customerIds: string[] = [];
    for (const promotion of value) {
      const promotionId = promotion.actionUrl || '';
      customerIds.push(promotionId);
      customerPromotions.value = customerIds;
      updatePromotionState(promotion);
    }
  }

  function setCustomerPromoNumbers(promotionsNumbers: number): void {
    customerPromoNumbers.value = promotionsNumbers;
  }

  function setRateButtonEnabled(value: boolean): void {
    isRateButtonEnabled.value = value;
  }

  function setInitialLikes(likes: number): void {
    promotionLikes.value = likes;
  }

  function setLikes(method: PromotionCustomerRatingMethodType): void {
    if (method === PromotionCustomerRatingMethodType.INCREASE) {
      promotionLikes.value += 1;
    } else {
      promotionLikes.value -= 1;
    }
  }

  // Getters:
  const groupByCategory = computed<Record<string, string[]>>(() => Object.keys(promotions.value)
    .reduce<Record<string, string[]>>((objectByCategory, promotionId) => {
      const value = promotions.value[promotionId].categoryId;
      const { isCustomerParticipating, isArchived } = promotions.value[promotionId];
      if (value && !isArchived) {
        return {
          ...objectByCategory,
          [value]: [...(objectByCategory[value] || []), promotionId],
          [customerCategoryId]: [
            ...objectByCategory[customerCategoryId],
            ...(isCustomerParticipating ? [promotionId] : []),
          ],
        };
      }
      return objectByCategory;
    }, { [customerCategoryId]: [] }));

  const allPromos = computed<PromotionsListItemProps[]>(
    () => Object.entries(promotions.value).filter(([key, item]) => !archivedPromotions.value.includes(key) && !item.isArchived).map(([, item]) => ({
      promotionId: item.actionUrl || '',
      isArchived: item.isArchived ?? false,
      isPromotionsLikeEnabled: false,
    })),
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const promotionsByCategory = computed<PromotionsByCategoryType[]>(() => {
    if (!promotionCategoriesNavigationItems.value?.length) return [];

    const items: PromotionsByCategoryType[] = [];

    for (const item of promotionCategoriesNavigationItems.value) {
      const hasCategory = !!item.children?.find((subChild: NavigationItem) => subChild.type === 'CATEGORY');

      if (item.id in groupByCategory.value) {
        items.push({
          id: item.id,
          value: item?.children
            ?.filter((child: NavigationItem) => child.id in promotions.value)
            .map((child: NavigationItem) => child.id) ?? [],
          name: item.caption || '',
          parentId: item.id,
          hasCategory,
        });
      }

      item.children?.forEach((subChild: NavigationItem) => {
        if (subChild.id in groupByCategory.value && subChild.children?.length) {
          items.push({
            id: subChild.id,
            value: subChild?.children
              ?.filter((child) => child.id in promotions.value)
              .map((child) => child.id) || [],
            name: subChild.caption || '',
            parentId: item.id,
            hasCategory,
          });
        }
      });
    }

    if (activeCategoryId.value) {
      const categoryGroups = items
        .filter((item) => item.parentId === activeCategoryId.value);

      if (categoryGroups.length) {
        return categoryGroups;
      }

      const categoryGroup = items
        .find((item) => item.id === activeCategoryId.value);

      if (categoryGroup) {
        return [categoryGroup];
      }
    }

    return items;
  });

  const filteredPromotionsByCategory = computed<PromotionsByCategoryType[]>(() => promotionsByCategory.value.filter((item) => item.value.length > 0));
  const promotionIsReady = computed(() => (activeCategoryId.value in filters.value));
  const customerCategory = computed<PromotionsCategoryType | null>(() => {
    // eslint-disable-next-line max-len
    const navCategory = promotionCategoriesNavigationItems.value?.find((item: NavigationItem) => item.id === customerCategoryId);

    if (!navCategory) {
      return null;
    }

    assert(navCategory.props, 'navCategory.props in not object');
    assert(isString(navCategory.props.type), 'navCategory.props.type is not string');
    assert(isCategoryType(navCategory.props.type), 'navCategory.props.type is not CategoryType');

    return {
      categoryId: navCategory.id,
      categoryType: navCategory.props.type,
    };
  });
  const categoryById = computed<PromotionsCategoryType | null>(() => {
    // eslint-disable-next-line max-len
    let navCategory = promotionCategoriesNavigationItems.value?.find((item: NavigationItem) => item.id === activeCategoryId.value);

    if (!navCategory) {
      for (const category of promotionCategoriesNavigationItems.value) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        category.children?.forEach((navigationSubItem: NavigationItem) => {
          if (navigationSubItem.id === activeCategoryId.value) {
            navCategory = navigationSubItem;
          }
        });
      }
    }

    if (!navCategory) {
      return null;
    }

    assert(navCategory.props, 'navCategory.props in not object');
    assert(isString(navCategory.props.type), 'navCategory.props.type is not string');
    assert(isCategoryType(navCategory.props.type), 'navCategory.props.type is not CategoryType');

    return {
      categoryId: navCategory.id,
      categoryType: navCategory.props.type,
    };
  });

  function categoryIdByCategoryType(categoryType: CategoryType): ComputedRef<string> {
    if (!categoryIdByCategoryTypeCache[categoryType]) {
      categoryIdByCategoryTypeCache[categoryType] = computed(() => {
        if (!promotionCategoriesNavigationItems.value) return '';

        const category = promotionCategoriesNavigationItems.value
          .find((item: NavigationItem) => item.props?.type === categoryType);

        if (!category) return '';

        return category.id;
      });
    }

    return categoryIdByCategoryTypeCache[categoryType];
  }

  const isCustomerParticipating = computed(() => participationTable.value[openedActionUrl.value]);
  // eslint-disable-next-line max-len
  // const promotionType = computed<PromoActionType | undefined | null>(() => promotions.value[openedActionUrl.value]?.promotionType);
  const promotion = computed<PromotionMergedDetailsDocument>(() => promotions.value[openedActionUrl.value]);
  // eslint-disable-next-line max-len
  const hasCounter = computed(() => !!promotion.value?.isShowStartDateCountdown || !!promotion.value?.isShowEndDateCountdown);
  const isBeforeStart = computed<boolean>(() => {
    if (!!promotion.value?.isShowStartDateCountdown && !!promotion.value?.isShowEndDateCountdown) {
      return false;
    }
    return !!promotion.value?.isShowStartDateCountdown;
  });
  const counterTimestamp = computed<number>(() => {
    if (isBeforeStart.value) {
      return promotion.value?.startDateCountdown || 0;
    }
    return promotion.value?.endDateNumber || 0;
  });
  const isMyActions = computed(() => activeCategoryId.value === customerCategoryId);
  const participationNumber = computed(() => customerPromoNumbers.value);
  const isBlockParticipationButton = computed(() => promotion.value?.isBlockParticipationButton);
  const getActivePromotions = computed<string[]>(() => filters.value[activeCategoryId.value] || []);

  // Actions:
  async function fetchPromotions(payload: PromotionsCategoryType): Promise<void> {
    /**
     * categoryType === CategoryType.CUSTOM will return empty array without categoryId
     */
    const options: GetPromotionsRequest = {
      ts: 0,
      categoryType: payload.categoryType || CategoryType.ALL,
      categoryId: payload.categoryId || null,
    };

    try {
      const data = await getPromotions(api,
        (node) => node.queries.promotions.getPromotions,
        { options });

      const { promotions: promotionsData } = data;

      if (options.categoryType === CategoryType.CUSTOMER) {
        setCustomerPromoNumbers(promotionsData.length || 0);
      }
      if (options.categoryType === CategoryType.CUSTOMER_ARCHIVED) {
        setFinishedPromotions(promotionsData);
      } else if (
        process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && options.categoryType === CategoryType.ARCHIVED
      ) {
        setArchivedPromotions(promotionsData);
      } else if (
        process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && options.categoryType === CategoryType.CUSTOMER
      ) {
        setCustomerPromotions(promotionsData);
      } else {
        setPromotions(promotionsData);
      }
    } catch (error) {
      setPromotions([]);
      throw error;
    }
    setPromotionsLoadedState(true);
  }

  async function loadPromotionDetails(actionUrl: string): Promise<void> {
    if (actionUrl) {
      setOpenedActiveUrl(actionUrl);
      const filterOptions: PromotionRequest = {
        actionUrl,
        pageSize: siteConfigStore.promotionsBlock?.leaderboardPageSize || 6,
        ts: 0,
      };
      const response = await getPromotionDetails(api,
        (node) => node.queries.promotions.getPromotion,
        { options: filterOptions });

      const {
        promotion: promotionResponse,
        isCustomerParticipating: isCustomerParticipatingResponse,
      } = response;
      if (response) {
        assert(isPromotion(promotionResponse), 'Is not promotion');
        updatePromotion(promotionResponse);
        setCustomerParticipation({
          actionUrl,
          isCustomerParticipating: !!isCustomerParticipatingResponse,
        });
      }
    }
  }

  async function loadPromotionDetailsWithPolling(data: { actionUrl: string; initial?: boolean }): Promise<void> {
    const { actionUrl, initial } = data;

    if (actionUrl) {
      await loadPromotionDetails(actionUrl);
      promotionDetailsPolling(actionUrl);

      if (!initial) {
        await navigationStore.fetchPromotionsNavigationConfig();
        await getPromotionsByCategoryType(CategoryType.CUSTOMER);
      }
    }
  }

  function promotionDetailsPolling(actionUrl: string): void {
    if (process.env.VUE_APP_RENDERING_SSR || process.env.VUE_APP_PRERENDER || !actionUrl) {
      return;
    }
    const { actionButton } = promotions.value[actionUrl];
    const isProcessing = actionButton?.buttonType === PromotionActionButtonType.PARTICIPATION_PROCESSING;

    if ($timeoutId) {
      Timer.clearTimeout($timeoutId);
      $timeoutId = 0;
    }

    if (isProcessing && $pollingInterval < DEFAULT_POLLING_INTERVAL * 10) {
      $timeoutId = Timer.setTimeout(() => {
        void loadPromotionDetailsWithPolling({ actionUrl });
        setPollingInterval($pollingInterval * 2);
      }, $pollingInterval);
    } else {
      setPollingInterval(DEFAULT_POLLING_INTERVAL);
    }
  }

  // @TODO: This method renamed getBonusWalletPromotion -> getBonusWalletPromotionAction
  async function getBonusWalletPromotionAction(): Promise<void> {
    const response = await getBonusWalletPromotion(api,
      (node) => node.queries.promotions.getBonusWalletPromotion);
    const { promotion: promotionResponse } = response;
    if (response) {
      assert(isPromotion(promotionResponse), 'Is not promotion');
      setBonusWallet(promotionResponse);
    }
  }

  async function bonusParticipation(options: Pick<SetParticipationRequest, 'actionId' | 'lbType'>): Promise<void> {
    try {
      await doPromotionParticipation(api,
        (node) => node.mutations.promotions.setParticipation,
        { options });
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);
      const errorCode: GqlApiResponseErrorCode = error.code;

      if (errorCode.equals('IDENTIFICATION_NEEDED')) {
        showIdentificationNeededModal();
      }
    }
  }

  async function getPromotionsByCategoryId(categoryIdValue = ''): Promise<void> {
    setActiveCategoryId(categoryIdValue);
    const categoryAll: PromotionsCategoryType = { categoryType: CategoryType.ALL, categoryId: null };
    await fetchPromotions(categoryById.value ?? categoryAll);
  }

  async function getPromotionsByCategoryType(categoryType: CategoryType): Promise<void> {
    await fetchPromotions({ categoryType, categoryId: null });
  }

  async function getListOfAll(categoryId: string): Promise<void> {
    promotions.value = {};
    setPromotionsLoadedState(false);
    await fetchPromotions({ categoryType: CategoryType.CUSTOM, categoryId });
  }

  async function getCustomerPromo(categoryId: string): Promise<void> {
    customerPromotions.value = [];
    setPromotionsLoadedState(false);
    await fetchPromotions({ categoryType: CategoryType.CUSTOMER, categoryId });
  }

  function promotionsSendYM(payload: { categoryName?: string; promotionName?: string }): void {
    let ymPromoActions: string | object = 'allPromo';

    if (payload.categoryName) {
      ymPromoActions = {
        [payload.categoryName]: payload.promotionName || 'all',
      };
    }

    analytics.push(AnalyticsEvent.Z_OPEN_PROMO_PAGE, {
      promoPage: {
        promoActions: ymPromoActions,
      },
    });
  }

  // @TODO: This method renamed doButtonMutation -> doButtonMutationAction
  async function doButtonMutationAction(payload: UpdatePromotionRequest): Promise<void> {
    const { actionUrl } = payload;
    const options: UpdatePromotionRequest = {
      ...payload,
      ts: 0,
    };
    const { actionButton } = await doButtonMutation(api,
      (node) => node.mutations.promotions.updatePromotion,
      { options });
    if (actionButton) {
      updatePromotionButton({ actionUrl, actionButton });
      promotionDetailsPolling(actionUrl);
    }
  }

  async function promotionRate(payload: RatePromotionRequest): Promise<void> {
    const response = await doPromotionRate(api,
      (node) => node.mutations.promotions.rate,
      {
        options: {
          ...payload,
        },
      });

    setRateButtonEnabled(false);

    if (response.result === 'OK') {
      setRateButtonEnabled(true);
    }
  }

  return {
    // State:
    openedActionUrl,
    promotions,
    bonusWallet,
    promotionsLoaded,
    finishedPromotions,
    archivedPromotions,
    customerPromotions,
    isRateButtonEnabled,
    promotionLikes,

    // Mutations:
    setPromotions,
    setOpenedActiveUrl,
    setInitialLikes,
    setLikes,

    // Getters:
    promotionsByCategory,
    promotionIsReady,
    customerCategory,
    categoryIdByCategoryType,
    isCustomerParticipating,
    promotion,
    hasCounter,
    isBeforeStart,
    counterTimestamp,
    isMyActions,
    participationNumber,
    isBlockParticipationButton,
    getActivePromotions,
    allPromos,
    filteredPromotionsByCategory,

    // Actions:
    fetchPromotions,
    loadPromotionDetails,
    loadPromotionDetailsWithPolling,
    getBonusWalletPromotionAction,
    bonusParticipation,
    getPromotionsByCategoryId,
    getPromotionsByCategoryType,
    promotionsSendYM,
    doButtonMutationAction,
    promotionRate,
    getListOfAll,
    getCustomerPromo,
    setActiveCategoryId,
  };
});

export default usePromotionsStore;
