import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import {
  computed,
  nextTick,
} from 'vue';

import { useActiveGameData } from 'web/src/modules/egs/store/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import {
  useIdentificationPhase,
  useIdentificationService,
} from 'web/src/modules/identification';
import type { EgsGame } from 'web/src/modules/egs/types';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';

const useQuickBetsStore = defineStore('quick-bets', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const gamesStore = useEgsGamesStore();

  const {
    activeGame,
    startGameData,
    isStartGameLoading,
    startGame: startGameAction,
    clearStartGameData,
    resetActiveGame,
    reloadActiveGame,
    setActiveUrl,
    getGameById: getGameByIdApi,
  } = useActiveGameData();

  let isDemo: Ref<boolean>;

  if (!process.env.VUE_APP_FEATURE_IDENT_ENABLED) {
    isDemo = computed(() => !isLoggedIn.value);
  } else {
    const { canGamble } = useIdentificationPhase();
    isDemo = computed(() => !isLoggedIn.value || !canGamble.value);
  }

  async function startGame(): Promise<void> {
    if (activeGame.value) {
      if (process.env.VUE_APP_FEATURE_IDENT_ENABLED && isLoggedIn.value) {
        await useIdentificationService().waitForGettingIdentificationState();
        // wait next tick for computed changes
        await nextTick();
      }
      await startGameAction({
        id: activeGame.value.id,
        isDemo: isDemo.value,
      });
    }
  }

  async function getGameById(id: string): Promise<EgsGame | undefined> {
    return gamesStore.getGameById(id) ?? getGameByIdApi(id);
  }

  return {
    isDemo,
    activeGame,
    startGameData,
    isStartGameLoading,
    startGame,
    clearStartGameData,
    resetActiveGame,
    reloadActiveGame,
    setActiveUrl,
    getGameById,
  };
});

export default useQuickBetsStore;
