/* eslint-disable sonarjs/cognitive-complexity */
import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { HistoryFilter } from '@leon-hub/api-sdk';
import { TargetParamWithdrawal } from '@leon-hub/yandex-metrika';
import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { CustomerHistoryListEmptyProps } from '../types';

export interface CustomerHistoryListEmptyComposable {
  buttonLabel: Ref<string>;
  emptyText: Ref<string>;
  doShowActionButton: Ref<boolean>;
  buttonClick: () => void;
}

export default function useCustomerHistoryListEmpty(
  props: CustomerHistoryListEmptyProps,
): CustomerHistoryListEmptyComposable {
  const router = useRouter();
  const { $translate } = useI18n();
  const analytics = useAnalytics();
  const homePageType = useHomePageType();

  const doShowActionButton = process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED
    ? toRef(() => true)
    : toRef(() => props.filter !== HistoryFilter.ALL);

  const emptyText = computed<string>(() => {
    switch (props.filter) {
      case HistoryFilter.ALL:
        return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL').value;
      case HistoryFilter.ALL_BETS:
        return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_BETS').value;
      case HistoryFilter.ALL_DEPOSITS:
        return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_DEPOSITS').value;
      case HistoryFilter.ALL_WITHDRAWALS:
        return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_WITHDRAWALS').value;
      case HistoryFilter.ALL_BONUS:
        return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_BONUSES').value;
      case HistoryFilter.ALL_EGS:
        return process.env.VUE_APP_FEATURE_CASINO_ENABLED
          ? $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_EGS').value
          : '';
      case HistoryFilter.LEONSHOP:
        return $translate('WEB2_SHOP_NO_HISTORY').value;
      default:
        return '';
    }
  });

  const buttonLabel = computed<string>(() => {
    switch (props.filter) {
      case HistoryFilter.ALL_BETS:
        return $translate('WEB2_CUSTOMER_HISTORY_MAKE_BET').value;
      case HistoryFilter.ALL_DEPOSITS:
        return $translate('WEB2_CUSTOMER_HISTORY_REPLENISH').value;
      case HistoryFilter.ALL_WITHDRAWALS:
        return $translate('WEB2_CUSTOMER_HISTORY_PAYMENTS').value;
      case HistoryFilter.ALL:
        return $translate('WEB2_CUSTOMER_HISTORY_MAKE_BET').value;
      case HistoryFilter.ALL_BONUS:
        return $translate('WEB2_CUSTOMER_HISTORY_TAKE_BONUS').value;
      case HistoryFilter.ALL_EGS:
        return $translate('WEB2_CUSTOMER_HISTORY_TO_SLOTS').value;
      case HistoryFilter.LEONSHOP:
        return $translate('WEB2_GO_TO_SHOP').value;
      default:
        return '';
    }
  });

  function buttonClick(): void {
    let name: null | string;
    let params: null | {} = null;

    switch (props.filter) {
      case HistoryFilter.ALL:
      case HistoryFilter.ALL_BETS:
        name = homePageType.routeNameToSportLine.value;
        break;
      case HistoryFilter.ALL_WITHDRAWALS:
        analytics.clickMap({ withdrawal: TargetParamWithdrawal.USER_PROFILE_HISTORY });
        name = RouteName.WITHDRAWALS;
        break;
      case HistoryFilter.ALL_DEPOSITS:
        analytics.clickMap({ deposit: 'userProfileHistoryDeposits' });
        name = RouteName.DEPOSITS;
        break;
      case HistoryFilter.ALL_BONUS:
        name = RouteName.PROMOTIONS;
        params = { categoryId: 'bonuses' };
        break;
      case HistoryFilter.ALL_EGS:
        name = process.env.VUE_APP_FEATURE_CASINO_ENABLED
          ? CasinoRouteName.CASINO_LOBBY
          : RouteName.ERROR_NOT_FOUND_404;
        break;
      case HistoryFilter.LEONSHOP:
        name = RouteName.LOYALTY_PROGRAM;
        break;
      default:
        name = null;
        break;
    }

    if (name) {
      const routerParams = params ? { name, params: { ...params } } : { name };
      void router.push(routerParams);
    }
  }

  return {
    buttonLabel,
    emptyText,
    doShowActionButton,
    buttonClick,
  };
}
