import type { ComputedRef } from 'vue';

import type { LandingSectionHeaderProps, LandingSectionFooterProps } from 'web/src/modules/landings/system/components/LandingSections/types';
import type { LandingStepListProps } from 'web/src/modules/landings/system/components/LandingStep/types';

export enum BannerType {
  FULL_DEFAULT = 'full-default',
  FULL_THIN = 'full-thin',
  SHORT_DEFAULT = 'short-default',
}

export interface LandingHeroPanelScreenProps {
  type?: BannerType;
  headerData: LandingSectionHeaderProps;
  footerData: LandingSectionFooterProps;
  imageSrcMobile: ComputedRef<string>;
  imageSrcDesktop: ComputedRef<string>;
  progressList?: LandingStepListProps;
  progressHeaderData?: LandingSectionHeaderProps;
  progressFooterData?: LandingSectionFooterProps;
}
