import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type { VLazyRenderEmits, VLazyRenderProps } from '../types';

export interface VLazyRenderComposable {
  collision: Ref<Element | null>;
  mounted(): void;
  stopObserving(): void;
  displayContent: ComputedRef<boolean>;
}

const available = process.env.VUE_APP_RENDERING_CSR
  && typeof window.IntersectionObserver === 'function';

export default function useVLazyRender(props: VLazyRenderProps, emit: VLazyRenderEmits): VLazyRenderComposable {
  const collision = ref(null);

  const isVisible = ref(!!process.env.VUE_APP_RENDERING_SSR);

  let intersectionObserver: null | IntersectionObserver = null;

  const displayContent = computed<boolean>(() => {
    const showByDefault = !props.isHideOnPrerender
      ? !!(process.env.VUE_APP_PRERENDER)
      : false;
    return showByDefault || !!isVisible.value;
  });

  function detectObserverAvailability(): void {
    if (!available) {
      handleIntersect();
    }
  }

  function handleIntersect(): void {
    isVisible.value = true;
    stopObserving();
    emit('appear');
  }

  function observe(): void {
    if (!collision.value || !available)
      return;
    try {
      const options: IntersectionObserverInit = { threshold: 0 };
      intersectionObserver = new IntersectionObserver((entries, observerInstance) => {
        if (entries[0].isIntersecting) {
          handleIntersect();
          observerInstance.disconnect();
        }
      }, options);
      intersectionObserver.observe(collision.value);
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
    }
  }

  function mounted(): void {
    detectObserverAvailability();
    observe();
  }

  function stopObserving(): void {
    if (intersectionObserver) {
      intersectionObserver.disconnect();
      intersectionObserver = null;
    }
  }

  return {
    collision,
    mounted,
    stopObserving,
    displayContent,
  };
}
