import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { BaseError } from '@leon-hub/errors';
import { getWithdrawalStats } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type {
  PaymentsProcedureStatus,
  UiFormSchemaDocument,
  WithdrawalStatsDocument,
} from 'web/src/modules/payments/types/documentTypes';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { PaymentsStatus } from 'web/src/modules/payments/enums';

const usePaymentsStore = defineStore('payments', () => {
  const gqlClient = useGraphqlClient();

  const mobileRedirectUrl = ref('/');

  const uiFormSchema = ref<Maybe<UiFormSchemaDocument>>(null);
  const withdrawalStatsData = ref<Maybe<WithdrawalStatsDocument>>(null);
  const lastDepositSystemsRequest = ref<number | null>(null);
  const loadingError = ref<BaseError | null>(null);

  const paymentError = ref<BaseError | null>(null);
  const paymentsProcedureStatus = ref<PaymentsProcedureStatus>({
    action: null,
    status: null,
  });

  const requestedCount = computed(() => withdrawalStatsData.value?.requestedCount ?? 0);
  const minPaymentCreatedAt = computed(() => withdrawalStatsData.value?.minPaymentCreatedAt ?? 0);

  function setPaymentsProcedureStatus(status: PaymentsProcedureStatus): void {
    paymentsProcedureStatus.value = { ...paymentsProcedureStatus.value, ...status };
  }

  function setLoadingError(value: BaseError | null): void {
    loadingError.value = value;
  }

  const mobileRedirectUrlFormatted = computed(() => {
    const excludedUrls = [RouteName.IDENTIFICATION];

    return excludedUrls.filter((url: string) => mobileRedirectUrl.value.includes(url)).length
      ? '/'
      : mobileRedirectUrl.value;
  });

  function setPreviousPageUrl(value: string): void {
    mobileRedirectUrl.value = value;
  }

  function errorHandler(error: BaseError): void {
    paymentError.value = error;
    if (paymentsProcedureStatus.value.action) {
      setPaymentsProcedureStatus({ status: PaymentsStatus.ERROR });
    }
  }

  async function loadWithdrawalStats(): Promise<void> {
    withdrawalStatsData.value = await getWithdrawalStats(gqlClient, (node) => node.queries.finance.getWithdrawalStats, { options: {} }, { silent: true });
  }

  return {
    setLoadingError,
    errorHandler,
    setPaymentsProcedureStatus,
    mobileRedirectUrl,
    mobileRedirectUrlFormatted,
    uiFormSchema,
    lastDepositSystemsRequest,
    loadingError,
    withdrawalStatsData,
    loadWithdrawalStats,
    setPreviousPageUrl,
    requestedCount,
    minPaymentCreatedAt,
  };
});

export default usePaymentsStore;
