import { RegistrationRouteName } from '@leon-hub/routing-config-names';
import { CustomerFieldType, CustomerRegistrationType, LoginType } from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { FormControlType } from '@leon-hub/form-utils';

import { RegistrationStage } from 'web/src/modules/registration/enums';
import { LOCAL_STORAGE_LOGIN_TAB_KEY } from 'web/src/modules/login/pages/LoginRoutePage/types';
import type {
  FormUiSchemaDefaultFields,
  FormUiSchemaField,
} from 'web/src/components/Form/types';
import RegistrationTypeAppsFlyer from 'web/src/modules/registration/enums/RegistrationTypeAppsFlyer';

export function mapRegistrationTabNameToLoginTabName(tabName: CustomerRegistrationType): LoginType | null {
  switch (tabName) {
    case CustomerRegistrationType.SIMPLE_EMAIL:
      return LoginType.EMAIL;
    case CustomerRegistrationType.SIMPLE_PHONE:
      return LoginType.PHONE;
    default: return null;
  }
}

export function saveActiveTabForLogin(tabName: CustomerRegistrationType): void {
  const loginTabName: LoginType | null = mapRegistrationTabNameToLoginTabName(tabName);
  if (loginTabName) {
    useLocalStorageManager().setItem(LOCAL_STORAGE_LOGIN_TAB_KEY, loginTabName);
  }
}

export const registrationStageRouteMap: Record<RegistrationStage, RegistrationRouteName> = {
  [RegistrationStage.PhoneCheck]: RegistrationRouteName.SIMPLE_REGISTRATION_PHONE_CHECK,
  [RegistrationStage.PhoneCheckByCall]: RegistrationRouteName.SIMPLE_REGISTRATION_PHONE_CHECK,
  [RegistrationStage.ChangePassword]: RegistrationRouteName.SIMPLE_REGISTRATION_CHANGE_PASSWORD,
  [RegistrationStage.ChangePasswordSuccess]: RegistrationRouteName.SIMPLE_REGISTRATION_CHANGE_PASSWORD_SUCCESS,
  [RegistrationStage.ChangePhone]: RegistrationRouteName.SIMPLE_REGISTRATION_CHANGE_PHONE,
  [RegistrationStage.Success]: RegistrationRouteName.SIMPLE_REGISTRATION_SUCCESS,
  [RegistrationStage.Main]: RegistrationRouteName.REGISTRATION,
};

const addCustomFormFieldProps = (options: {
  field: FormUiSchemaField;
  customProps?: Partial<FormUiSchemaField>;
  customOptions?: Partial<FormUiSchemaField['options']>;
})
: FormUiSchemaField => {
  const { field } = options;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const customProps = options.customProps || {};
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const customOptions = options.customOptions || {};
  const combinedOptions: FormUiSchemaField['options'] = field.options ? {
    ...field.options,
    ...customOptions,
  } : {
    ...customOptions,
  };
  return {
    ...field,
    ...customProps,
    options: combinedOptions,
  } as FormUiSchemaField;
};

export function getDefaultCountryCode(geoIpCountryCode: string, fields: FormUiSchemaDefaultFields)
  : string | undefined {
  const countrySelectField = fields?.[CustomerFieldType.COUNTRY_SELECT];
  if (countrySelectField) {
    const geoIpCountryIsAllowed = 'labels' in countrySelectField && countrySelectField.labels?.[geoIpCountryCode];
    if (geoIpCountryIsAllowed) {
      return geoIpCountryCode;
    }
  }
  return undefined;
}

export function formatRegistrationFormFields(input: {
  fields: FormUiSchemaDefaultFields;
  selectedCountryCode: string | null;
  defaultCountryCode: string | undefined;
  isRegistrationDisabled?: boolean;
  defaultEmail?: string;
  defaultNickname?: Maybe<string>;
}): FormUiSchemaDefaultFields {
  const {
    fields,
    selectedCountryCode,
    defaultCountryCode,
    isRegistrationDisabled,
    defaultEmail,
    defaultNickname,
  } = input;
  const result: FormUiSchemaDefaultFields = {};
  const keys = Object.keys(fields);
  for (const key of keys) {
    let current = fields[key];
    if (current.widget === FormControlType.AutocompleteAddress || current.widget === FormControlType.CurrencySelect) {
      current = addCustomFormFieldProps({
        field: current,
        customOptions: {
          countryCode: selectedCountryCode || defaultCountryCode,
        },
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (key === CustomerFieldType.EMAIL && defaultEmail) {
      current = addCustomFormFieldProps({
        field: current,
        customProps: {
          default: defaultEmail,
        },
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (key === CustomerFieldType.NICKNAME && defaultNickname) {
      current = addCustomFormFieldProps({
        field: current,
        customProps: {
          default: defaultNickname,
        },
      });
    }
    result[key] = {
      ...current,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled: isRegistrationDisabled || current.disabled,
    };
  }
  return result;
}

export function getRegistrationTypeAppsFlyer(type: CustomerRegistrationType): RegistrationTypeAppsFlyer | '' {
  if (type === CustomerRegistrationType.SIMPLE_PHONE) {
    return RegistrationTypeAppsFlyer.BY_PHONE;
  }
  if (type === CustomerRegistrationType.SIMPLE_EMAIL) {
    return RegistrationTypeAppsFlyer.BY_EMAIL;
  }

  return '';
}
