import { defineStore } from 'pinia';
import {
  ref,
  toRef,
  computed,
  watch,
} from 'vue';

import type {
  Ref
} from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import type { CustomerNotificationMessage, CustomerNotificationCheckedMessage } from 'web/src/modules/customer-notifications/types';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import promisesInBatch from 'web/src/utils/promisesInBatch';
import useFastTrackApi from 'web/src/modules/fast-track/services/api/useFastTrackApi';

import { createMessageFromFastTrack } from './utils';
import PusherJsClient from '../services/pusher-js/PusherJsClient';
import type { FastTrackMessage, FastTrackDeleteMessageResponse } from '../services/api/types';

interface UseFastTrackStore {
  messages: Ref<FastTrackMessage[]>;
  preparedMessages: Ref<CustomerNotificationMessage[]>;
  unreadCounter: Ref<number>;
  fetchMessages: () => Promise<void>;
  setMessageAsRead: (message: CustomerNotificationMessage) => Promise<void>;
  deleteMessage: (message: CustomerNotificationMessage) => Promise<void>;
  deleteMultiple: <T extends FastTrackMessage>(options: { messagesArray: T[] | undefined; isAllDeleting?: boolean }) => Promise<CustomerNotificationCheckedMessage[]>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const useFastTrackStore = defineStore('fast-track', (): UseFastTrackStore => {
  if (process.env.VUE_APP_RENDERING_SSR) {
    return {
      messages: toRef(() => []),
      preparedMessages: toRef(() => []),
      unreadCounter: toRef(() => 0),
      fetchMessages: () => Promise.resolve(),
      setMessageAsRead: () => Promise.resolve(),
      deleteMessage: () => Promise.resolve(),
      deleteMultiple: () => Promise.resolve([]),
    };
  }
  const customerDataStore = useCustomerDataStore();
  const siteConfigStore = useSiteConfigStore();
  const fastTrackBlock = toRef(siteConfigStore, 'fastTrackBlock');
  const configApiUrl = computed(() => fastTrackBlock.value?.notificationsConfigApiUrl || '');
  const requestTimeout = computed(() => fastTrackBlock.value?.requestTimeout || 15000);
  const customerData = toRef(customerDataStore, 'customerData');
  const fastTrackSessionId = computed(() => customerData.value?.fastTrackToken || '');

  const api = useFastTrackApi(requestTimeout);
  const { isLoggedIn } = useIsLoggedIn();

  let pusherClient: PusherJsClient | null = null;
  let fusionUrl = '';
  let pusherKey = '';
  let pusherRegion = '';
  let authToken = '';

  const messages = ref<FastTrackMessage[]>([]);
  const preparedMessages = computed<CustomerNotificationMessage[]>(() => {
    if (!configApiUrl.value) {
      return [];
    }

    return messages.value.map((message) => createMessageFromFastTrack(message));
  });
  const unreadCounter = computed(() => messages.value?.filter((message) => !message.IsRead).length || 0);

  const isSupported = computed(() => (
    !!configApiUrl.value && isLoggedIn.value && !!fastTrackSessionId.value
  ));

  async function initBaseEndpoints(): Promise<void> {
    if (!fusionUrl && isSupported.value) {
      try {
        const endpoints = await api.getFastTrackBaseEndpoints({
          endpoint: configApiUrl.value,
        });

        if (endpoints.fusionUrl && endpoints.pusherKey && endpoints.pusherRegion) {
          fusionUrl = endpoints.fusionUrl;
          pusherKey = endpoints.pusherKey;
          pusherRegion = endpoints.pusherRegion;
        }
      } catch {
        messages.value = [];
      }
    }
  }

  function addMessage(message: FastTrackMessage): void {
    if (!messages.value) {
      messages.value = [message];
      return;
    }

    if (!messages.value.some(({ MessageId }) => MessageId === message.MessageId)) {
      messages.value.unshift(message);
    }
  }

  async function doLogin(): Promise<void> {
    if (isSupported.value
      && fusionUrl
      && fastTrackBlock.value) {
      try {
        const loginResponse = await api.doFastTrackLogin({
          fusionUrl,
          sessionId: fastTrackSessionId.value,
        });

        if (loginResponse.Success && loginResponse.Data?.User.UserId) {
          authToken = loginResponse.Data.Authentication.AuthToken;

          if (!pusherClient) {
            pusherClient = new PusherJsClient();
          }

          await pusherClient.connect({
            fusionUrl,
            pusherKey,
            pusherRegion,
            brandId: fastTrackBlock.value.brandId,
            brandName: fastTrackBlock.value.brandName || '',
            sessionId: fastTrackSessionId.value,
            userId: loginResponse.Data.User.UserId,
            onNewPusherMessage: (message) => {
              if (isLoggedIn.value) {
                addMessage(message);
              }
            },
          });
        }
      } catch {
        messages.value = [];
      }
    }
  }

  async function fetchMessages(): Promise<void> {
    if (isSupported.value
      && fusionUrl
      && fastTrackBlock.value
      && authToken) {
      try {
        const messagesResponse = await api.getFastTrackMessages({
          fusionUrl,
          authToken,
          unreadOnly: false,
          inboxOnly: false,
        });

        if (messagesResponse.Success && messagesResponse.Data) {
          messages.value = messagesResponse.Data;
        } else {
          messages.value = [];
        }
      } catch {
        messages.value = [];
      }
    }
  }

  function markAsRead(id: number | string): void {
    const message = messages.value?.find((item) => item.MessageId === id);
    if (message) {
      message.IsRead = true;
    }
  }

  async function setMessageAsRead(message: CustomerNotificationMessage): Promise<void> {
    if (message.source === CustomerNotificationMessageSource.FAST_TRACK) {
      const response = await api.markFastTrackMessageAsRead({
        fusionUrl,
        authToken,
        messageId: message.id,
      });

      if (response.Success) {
        markAsRead(message.id);
      }
    } else {
      throw new Error('You cannot call setRead message on not FastTrack message type');
    }
  }

  async function initFastTrack(): Promise<void> {
    await initBaseEndpoints();

    if (fusionUrl && fastTrackBlock.value) {
      await doLogin();
      await fetchMessages();
    }
  }

  async function fetchDelete(id: number): Promise<FastTrackDeleteMessageResponse> {
    try {
      const response = await api.deleteFastTrackMessage({
        fusionUrl,
        authToken,
        messageId: id,
      });
      if (!response.Success) {
        await Promise.reject(response);
      }
      return response;
    } catch (err: unknown) {
      return Promise.reject(err);
    }
  }

  async function deleteMessage(message: CustomerNotificationMessage): Promise<void> {
    if (message.source === CustomerNotificationMessageSource.FAST_TRACK) {
      const stateMessage = messages.value?.find(({ MessageId }) => MessageId === message.id);

      if (stateMessage && messages.value) {
        messages.value = messages.value.filter(({ MessageId }) => MessageId !== message.id);

        try {
          await fetchDelete(message.id);
        } catch {
          addMessage(stateMessage);
        }
      }
    } else {
      throw new Error('You cannot call delete message on not FastTrack message type');
    }
  }

  async function deleteMultiple<T extends FastTrackMessage>(
    { messagesArray = [], isAllDeleting }: { messagesArray: T[] | undefined; isAllDeleting?: boolean },
  ): Promise<CustomerNotificationCheckedMessage[]> {
    const failedMessages: T[] = [];
    const deleteIdList = messagesArray ? messagesArray.map(({ MessageId }) => MessageId) : [];
    if (!isAllDeleting && !deleteIdList.length) return [];
    if (!messages.value?.length) return [];

    messages.value = isAllDeleting ? [] : messages.value.filter(({ MessageId }) => !deleteIdList.includes(MessageId));

    await promisesInBatch(messagesArray || [], (message) => fetchDelete(message.MessageId).catch(() => {
      failedMessages.push(message);
    }));

    if (failedMessages.length) {
      messages.value = isAllDeleting ? failedMessages : [...(messages.value || []), ...failedMessages];
    }

    return failedMessages.map((item) => ({
      id: item.MessageId,
      source: CustomerNotificationMessageSource.FAST_TRACK,
    }));
  }

  function disconnectFastTrack(): void {
    pusherClient?.disconnect();
    fusionUrl = '';
    messages.value = [];
    authToken = '';
  }

  watch(isSupported, (newValue) => {
    if (newValue) {
      void initFastTrack();
    } else {
      disconnectFastTrack();
    }
  }, {
    immediate: true,
  });

  return {
    messages,
    preparedMessages,
    unreadCounter,
    fetchMessages,
    setMessageAsRead,
    deleteMessage,
    deleteMultiple,
  };
});

export default useFastTrackStore;
