import { logger } from '@leon-hub/logging';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import ServerDate from 'web/src/utils/ServerDate';
import type { SlipMappingKey } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import type { VirtualSportSlipMapping } from 'web/src/modules/sportline/submodules/virtual-sport/types';

export default class VirtualSportBetsMappingStorage {
  private static releaseMappingAfter = 24 * 60 * 60 * 1000;

  private static localStorageKey = 'web2_virtual_sport_slip_mappings';

  private static isKeysEqual(key1: SlipMappingKey, key2: SlipMappingKey): boolean {
    return key1.selectionKey === key2.selectionKey
      && key1.sport === key2.sport
      && key1.marketSpecifier === key2.marketSpecifier
      && key1.marketId === key2.marketId
      && key1.outcomeId === key2.outcomeId
      && key1.matchId === key2.matchId;
  }

  private static isMappingsEqual(value1: VirtualSportSlipMapping, value2: VirtualSportSlipMapping): boolean {
    return value1.runnerId === value2.runnerId
      && value1.marketId === value2.marketId
      && value1.eventId === value2.eventId;
  }

  private slipMappings: [SlipMappingKey, VirtualSportSlipMapping, number][] = [];

  constructor() {
    this.loadFromLocalStorage();
    this.releaseOldMappings();
  }

  getKeysByMappings(mappings: VirtualSportSlipMapping[]): SlipMappingKey[] {
    return this.slipMappings
      .filter(([, value]) => mappings.find((mapping) => VirtualSportBetsMappingStorage.isMappingsEqual(mapping, value)))
      .map(([key]) => key);
  }

  getSlipMapping(bet: SlipMappingKey): VirtualSportSlipMapping | undefined {
    return this.slipMappings
      .find(([key]) => VirtualSportBetsMappingStorage.isKeysEqual(key, bet))?.[1];
  }

  setSlipMapping(bet: SlipMappingKey, slipMapping?: VirtualSportSlipMapping): VirtualSportSlipMapping | undefined {
    if (!slipMapping) {
      return undefined;
    }

    const key: SlipMappingKey = {
      selectionKey: bet.selectionKey,
      sport: bet.sport,
      marketSpecifier: bet.marketSpecifier,
      marketId: bet.marketId,
      outcomeId: bet.outcomeId,
      matchId: bet.matchId,
      validity: bet.validity,
    };
    this.slipMappings.push([key, slipMapping, ServerDate.now()]);
    this.saveToLocalStorage();

    return slipMapping;
  }

  private releaseOldMappings(): void {
    const releaseDate = ServerDate.now() - VirtualSportBetsMappingStorage.releaseMappingAfter;
    this.slipMappings = this.slipMappings.filter((mapping) => (mapping[2] > releaseDate));
  }

  private loadFromLocalStorage(): void {
    try {
      const asString = useLocalStorageManager().getItem(VirtualSportBetsMappingStorage.localStorageKey);

      if (!asString) {
        this.slipMappings = [];
        return;
      }

      this.slipMappings = JSON.parse(asString);
    } catch (error) {
      logger.error(`Virtual sport bets mapping loadFromLocalStorage error: ${error}`);
    }
  }

  private saveToLocalStorage(): void {
    try {
      const asString = JSON.stringify(this.slipMappings);
      if (asString) {
        useLocalStorageManager().setItem(VirtualSportBetsMappingStorage.localStorageKey, asString);
      }
    } catch (error) {
      logger.error(`Virtual sport bets mapping saveToLocalStorage error: ${error}`);
    }
  }
}
