import type { BaseRouteNameType, SharedRouteNameType } from '@leon-hub/routing-config-names';
import {
  RouteName,
  CasinoRouteName,
} from '@leon-hub/routing-config-names';
import { CustomerConfig } from '@leon-hub/api-sdk';

import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';

export interface OnboardingConfig {
  customerConfig?: CustomerConfig;
  infoLinkRouteName?: BaseRouteNameType;
  topBarTitleBTR?: string;
  routeBack?: SharedRouteNameType; // @TODO: wait test
}

export default function getOnboardingConfig(id: OnboardingIdsEnum): OnboardingConfig {
  if (id === OnboardingIdsEnum.REFERRAL_PROGRAM) {
    return {
      customerConfig: CustomerConfig.IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN,
      infoLinkRouteName: RouteName.REFERRAL_PROGRAM_INFO,
      topBarTitleBTR: 'WEB2_REFFERAL_PROGRAM_INVITE',
    };
  }

  if (id === OnboardingIdsEnum.BONUSES) {
    return {
      customerConfig: CustomerConfig.HIDE_ON_BOARDING_PAGE,
      routeBack: RouteName.BONUSES,
    };
  }

  if (id === OnboardingIdsEnum.LEONSHOP) {
    return {
      customerConfig: CustomerConfig.HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE,
      routeBack: RouteName.LOYALTY_PROGRAM,
    };
  }

  if (process.env.VUE_APP_FEATURE_CASINO_LOYALTY_PROGRAM_ENABLED && id === OnboardingIdsEnum.EGS_LOYALTY_PROGRAM) {
    return {
      customerConfig: CustomerConfig.HIDE_EGS_LOYALTY_ON_BOARDING_PAGE,
      routeBack: CasinoRouteName.CASINO_LOYALTY_PROGRAM,
    };
  }

  if (process.env.VUE_APP_FEATURE_ACHIEVEMENTS_ENABLED && id === OnboardingIdsEnum.ACHIEVEMENTS_MAIN) {
    return {
      customerConfig: CustomerConfig.IS_ACHIEVEMENTS_MAIN_ONBOARDING_SHOWN,
    };
  }

  return {};
}
