import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import type { BetSlipSaveRequest } from '@leon-hub/api-sdk';
import {
  uiAccountTypeMain,
} from '@leon-hub/api-sdk';
import { getCordovaAppConfig } from '@leon-hub/cordova';
import { isUndefined } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

import { useShareEventLogging } from '../../../composable';
import { usePlaceBetStore } from '../../place-bet/store';
import { getMakeBetEntryItems } from '../../place-bet/utils/getMakeBetEntryItems';
import { useSlipInfoStore } from '../../slip-info/store';
import type { CachedSharedBet, DataForSharing, ShareBetByIdPayload } from '../types';
import { requestSaveBetSlip, requestSavePlacedBet } from '../utils';

const useSlipShareStore = defineStore('slip-share-store', () => {
  const apiClient = useGraphqlClient();

  const siteConfigStore = useSiteConfigStore();

  const { isLoggedIn } = useIsLoggedIn();

  const { convertToBaseError } = useErrorsConverter();

  const { logUnableToShare } = useShareEventLogging();

  const webIntent = computed<string | undefined>(() => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const { deepLinks } = getCordovaAppConfig();
      return deepLinks.intents.webIntent;
    }
    return undefined;
  });

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const sharedBetSlipBookingCodeEnabled = computed<boolean>(() => slipBlock.value?.sharedBetSlipBookingCodeEnabled ?? false);
  const sharedBetSlipLinkEnabled = computed<boolean>(() => slipBlock.value?.sharedBetSlipLinkEnabled ?? false);

  const isSlipShareEnabled = computed<boolean>(() => isLoggedIn.value && (sharedBetSlipBookingCodeEnabled.value || sharedBetSlipLinkEnabled.value));

  const isCurrentSlipShareEnabled = computed<boolean>(() => isSlipShareEnabled.value && !!slipBlock.value?.currentSlipShareEnabled);

  const isCustomerBetShareEnabled = computed<boolean>(() => isSlipShareEnabled.value && !!slipBlock.value?.customerBetShareEnabled);

  const slipInfoStore = useSlipInfoStore();

  const slipEntries = toRef(slipInfoStore, 'slipEntries');

  const slipType = toRef(slipInfoStore, 'slipType');

  const lastPlacedSingleEntries = toRef(usePlaceBetStore(), 'lastPlacedSingleEntries');

  const bookingCode = ref<string | null>(null);

  const imageUrl = ref<string | null>(null);

  const shareLink = ref<string | null>(null);

  const amountSwitcherIsVisible = ref<boolean>(false);

  const showAmountEnabled = ref<boolean>(false);

  const shareModalShown = ref<boolean>(false);

  const isSharingPlacedBet = ref<boolean>(false);

  const isPending = ref<boolean>(false);

  const errorMessage = ref<string | null>(null);

  const setPending = (value: boolean): void => {
    isPending.value = value;
  };

  const setErrorMessage = (value: string | null): void => {
    errorMessage.value = value;
  };

  const setShareData = (data: DataForSharing | null): void => {
    bookingCode.value = data?.bookingCode ?? null;
    shareLink.value = data?.sharedLink ?? '';
    imageUrl.value = data?.imgSrc ?? null;
  };

  const handleError = (error: unknown): void => {
    const baseError = convertToBaseError(error);
    logger.error(baseError);
    setErrorMessage(baseError.message);
    logUnableToShare(baseError.message);
    setPending(false);
  };

  const shareSlip = async (input: Omit<BetSlipSaveRequest, 'domain'>): Promise<void> => {
    setPending(true);
    try {
      const savedSlipData = await requestSaveBetSlip({
        ...input,
        domain: webIntent.value,
      }, apiClient);
      if (savedSlipData) {
        setShareData(savedSlipData);
        isSharingPlacedBet.value = false;
        setPending(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const shareCurrentSlipData = (): void => {
    if (!slipEntries.value || !slipType.value) {
      return;
    }
    void shareSlip({
      slipEntries: getMakeBetEntryItems(slipEntries.value),
      slipType: slipType.value,
    });
  };

  const shareLastPlacedSingles = (): void => {
    if (!lastPlacedSingleEntries.value.length) {
      return;
    }
    void shareSlip({
      slipEntries: getMakeBetEntryItems(lastPlacedSingleEntries.value),
      slipType: 's',
    });
  };

  let lastSharePayload: ShareBetByIdPayload | null = null;

  let cachedSharedBets: CachedSharedBet[] = [];

  const getSharedBetFromApi = async (payload: ShareBetByIdPayload): Promise<void> => {
    setErrorMessage(null);
    if (!payload.doNotSetPending) {
      setPending(true);
    }
    try {
      const savedSlipData = await requestSavePlacedBet({
        betId: payload.betId,
        // uiAccountTypeMain is default, uiAccountTypeSbc is being used for freebet
        accountType: payload.accountType ?? uiAccountTypeMain,
        showAmount: payload.showAmount ?? false,
        domain: webIntent.value,
      }, apiClient);
      cachedSharedBets.push({ payload, sharedData: savedSlipData });
      setShareData(savedSlipData);
      setPending(false);
    } catch (error) {
      handleError(error);
    }
  };

  const shareBetById = async (payload: ShareBetByIdPayload): Promise<void> => {
    lastSharePayload = payload;
    if (!isUndefined(payload.showAmount)) {
      showAmountEnabled.value = payload.showAmount;
    }
    isSharingPlacedBet.value = true;
    const matchedCacheRecord = cachedSharedBets.find((item) => (item.payload.betId === payload.betId) && (item.payload.showAmount === payload.showAmount));
    if (matchedCacheRecord) {
      setShareData(matchedCacheRecord.sharedData);
      return;
    }
    await getSharedBetFromApi(payload);
  };

  const regenerateSharePicture = (showAmount: boolean): void => {
    if (lastSharePayload) {
      imageUrl.value = null;
      void shareBetById({
        ...lastSharePayload,
        showAmount,
        doNotSetPending: true,
      });
    }
  };

  const showSharedModal = (): void => {
    shareModalShown.value = true;
  };

  const showAmountSwitcher = (): void => {
    amountSwitcherIsVisible.value = true;
  };

  const canselShare = (): void => {
    setShareData(null);
    setErrorMessage(null);
    shareModalShown.value = false;
    amountSwitcherIsVisible.value = false;
    showAmountEnabled.value = false;
    lastSharePayload = null;
    cachedSharedBets = [];
  };

  return {
    bookingCode,
    imageUrl,
    shareLink,
    shareModalShown,
    isSlipShareEnabled,
    amountSwitcherIsVisible,
    showAmountEnabled,
    isCurrentSlipShareEnabled,
    isCustomerBetShareEnabled,
    isSharingPlacedBet,
    isPending,
    errorMessage,
    canselShare,
    shareCurrentSlipData,
    shareLastPlacedSingles,
    shareBetById,
    showSharedModal,
    showAmountSwitcher,
    regenerateSharePicture,
  };
});

export default useSlipShareStore;
