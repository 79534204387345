import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import type {
  Router,
} from 'vue-router';
import {
  useRouter,
} from 'vue-router';
import type { Meta, Script } from '@unhead/vue';

import { isArray } from '@leon-hub/guards';
import type { MetaInfo } from '@leon-hub/routing-config';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useRouterStore } from 'web/src/modules/core/store';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
import type {
  SeoMetaParameters,
  SpintaxSeoMetaData,
} from 'web/src/modules/seo/types';
import replaceMetaParametersPlaceholders from 'web/src/modules/seo/utils/replaceMetaParametersPlaceholders';
import {
  getMetaScriptsTemplatesFromMetaInfo,
  getMetaScriptsFromMetaInfo,
  getMetaTagsFromMetaInfo,
  getMetaTitleFromMetaInfo,
} from 'web/src/modules/seo/utils/extractMetaInfo';

interface SeoMetaPropertiesComposable {
  metaParameters: Ref<SeoMetaParameters>;
  metaTitle: Ref<string>;
  metaTags: Ref<Meta[]>;
  metaScripts: Ref<Script[]>;
  spintaxMetaData: Ref<Maybe<SpintaxSeoMetaData>>;
  spintaxMetaInfo: Ref<Optional<MetaInfo>>;
}

export function useSeoMetaTitleProperty(props: {
  spintaxMetaInfo: Ref<Optional<MetaInfo>>;
  metaInfoConfig: Ref<Optional<MetaInfo>>;
  metaParameters: Ref<SeoMetaParameters>;
}): Ref<string> {
  const {
    spintaxMetaInfo,
    metaInfoConfig,
    metaParameters,
  } = props;
  const { $translate } = useI18n();
  const defaultTitle = $translate('WEB2_SEO_DEFAULT_TITLE');
  return computed<string>(() => {
    const spintaxMetaTitle = getMetaTitleFromMetaInfo(spintaxMetaInfo.value);

    if (spintaxMetaTitle) {
      return spintaxMetaTitle;
    }

    const metaInfoConfigTitle = getMetaTitleFromMetaInfo(metaInfoConfig.value);

    return replaceMetaParametersPlaceholders(metaInfoConfigTitle || '', metaParameters.value)
      || defaultTitle.value;
  });
}

export function useSeoMetaTagsProperty(props: {
  spintaxMetaInfo: Ref<Optional<MetaInfo>>;
  metaInfoConfig: Ref<Optional<MetaInfo>>;
  metaParameters: Ref<SeoMetaParameters>;
}): Ref<Meta[]> {
  const {
    spintaxMetaInfo,
    metaInfoConfig,
    metaParameters,
  } = props;
  return computed<Meta[]>(() => {
    const spintaxMetaTags = getMetaTagsFromMetaInfo(spintaxMetaInfo.value);
    const metaInfoMetaTags = getMetaTagsFromMetaInfo(metaInfoConfig.value);
    const result: Dictionary<Meta> = {};

    for (const value of spintaxMetaTags) {
      if (value.content) {
        result[value.name] = {
          name: value.name,
          content: value.content,
        };
      }
    }

    for (const value of metaInfoMetaTags) {
      // skip exist property
      if (result[value.name]) { continue; }
      result[value.name] = {
        name: value.name,
        content: replaceMetaParametersPlaceholders(value.content, metaParameters.value) || '',
      };
    }

    return Object.values(result);
  });
}

export function useSeoMetaScriptsProperty(props: {
  metaInfoConfig: Ref<Optional<MetaInfo>>;
  metaParameters: Ref<SeoMetaParameters>;
}): Ref<Script[]> {
  const {
    metaInfoConfig,
    metaParameters,
  } = props;
  return computed<Script[]>(() => {
    const metaInfoMetaScripts = getMetaScriptsFromMetaInfo(metaInfoConfig.value);
    const metaInfoMetaScriptsTemplates = getMetaScriptsTemplatesFromMetaInfo(metaInfoConfig.value);

    const tags = metaInfoMetaScripts.map((value): Script => ({
      type: value.type,
      innerHTML: replaceMetaParametersPlaceholders(value.content, metaParameters.value) || '',
    }));
    const templates = metaInfoMetaScriptsTemplates.reduce<Script[]>((result, value) => {
      const dataSet = metaParameters.value[value.template];

      if (dataSet && isArray<SeoMetaParameters>(dataSet)) {
        return [
          ...result,
          ...dataSet.map((data) => ({
            type: value.type,
            innerHTML: replaceMetaParametersPlaceholders(value.content, data) || '',
          })),
        ];
      }

      return result;
    }, []);

    return [
      ...tags,
      ...templates,
    ];
  });
}

/**
 * Computed App properties
 */
export default function useSeoMetaProperties(
  options?: { router?: Router },
): SeoMetaPropertiesComposable {
  const routerStore = useRouterStore();
  const seoMetaStorage = useSeoMetaStorage();

  const seoConfigs = toRef(routerStore, 'seoConfigs');
  const metaParameters = toRef(seoMetaStorage, 'metaParameters');
  const spintaxMetaData = toRef(seoMetaStorage, 'spintaxMetaData');
  const spintaxMetaInfo = toRef(seoMetaStorage, 'spintaxMetaInfo');

  // vue-router use inject logic, so outside the app, it is empty
  const router = options?.router ?? useRouter();

  const metaInfoConfig = computed<Optional<MetaInfo>>(() => {
    const route = router.currentRoute.value;
    return route ? seoConfigs.value[String(route.name)]?.metaInfo : undefined;
  });

  const metaTitle = useSeoMetaTitleProperty({ spintaxMetaInfo, metaInfoConfig, metaParameters });
  const metaTags = useSeoMetaTagsProperty({ spintaxMetaInfo, metaInfoConfig, metaParameters });
  const metaScripts = useSeoMetaScriptsProperty({ metaInfoConfig, metaParameters });

  return {
    metaParameters,
    metaTitle,
    metaTags,
    metaScripts,
    spintaxMetaData,
    spintaxMetaInfo,
  };
}
