import { toRef } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useTheme } from 'web/src/modules/theme/composables';

export interface UseCasinoGameAnalytics {
  sendOpenGameMetrika(isDemo?: boolean): void;
}

export default function useCasinoGameAnalytics(): UseCasinoGameAnalytics {
  const analytics = useAnalytics();
  const gamesStore = useCasinoGameStore();
  const activeGame = toRef(gamesStore, 'activeGame');
  const { theme } = useTheme();

  function sendOpenGameMetrika(isDemo?: boolean): void {
    const event = isDemo ? AnalyticsEvent.Z_OPEN_DEMO_GAME : AnalyticsEvent.Z_OPEN_GAME;
    const vendorType = isDemo ? 'demoGameVendor' : 'gameVendor';
    const lobbyType = activeGame.value?.typeId || '';
    const providerName = activeGame.value?.group?.name || '';
    const gameName = activeGame.value?.name || '';

    analytics.push(event, {
      [vendorType]: {
        [lobbyType]: {
          [providerName]: gameName,
        },
        mode: theme.value.toLowerCase(),
      },
    });
  }

  return {
    sendOpenGameMetrika,
  };
}
