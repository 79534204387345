<script setup lang="ts">
import type {
  LandingHeroPanelScreenProps,
} from 'web/src/modules/landings/system/components/LandingHeroPanelScreen/types';
import {
  BannerType,
} from 'web/src/modules/landings/system/components/LandingHeroPanelScreen/types';
import { MaxLabelLength } from 'web/src/modules/landings/system/components/LandingLabel/types';
import LandingSectionFooter from 'web/src/modules/landings/system/components/LandingSections/LandingSectionFooter.vue';
import LandingSectionHeader from 'web/src/modules/landings/system/components/LandingSections/LandingSectionHeader.vue';
import LandingStepList from 'web/src/modules/landings/system/components/LandingStep/LandingStepList.vue';
import { useTheme } from 'web/src/modules/theme/composables';

withDefaults(defineProps<LandingHeroPanelScreenProps>(), {
  type: BannerType.FULL_DEFAULT,
});
const { isDark } = useTheme();
</script>

<template>
  <div v-auto-id="'LandingHeroPanelScreen'"
    :class="{
      [$style['landing-hero-panel-screen']]: true,
      [$style[`landing-hero-panel-screen--${type}`]]: type !== BannerType.FULL_DEFAULT,
    }"
  >
    <div :class="$style['landing-hero-panel-screen__panel']">
      <div
        :class="$style['landing-hero-panel-screen__content']"
      >
        <div :class="$style['landing-hero-panel-screen__flexible-container']">
          <LandingSectionHeader
            v-bind="headerData"
            :max-length-label="type === BannerType.SHORT_DEFAULT ? MaxLabelLength.SHORT_STRING : MaxLabelLength.MEDIUM_STRING"
          />
          <div :class="$style['landing-hero-panel-screen__image']">
            <img
              v-if="null"
              width="100%"
              :src="imageSrcMobile.value"
            >
          </div>
          <LandingSectionFooter v-bind="footerData" />
        </div>
      </div>
      <div
        :class="$style['landing-hero-panel-screen__bg-image']"
        :style="{ backgroundImage: `url(${imageSrcDesktop.value})` }"
      />
    </div>
    <div
      v-if="progressList"
      id="landing_progress"
      :class="{
        [$style['landing-hero-panel-screen__panel']]: true,
        [$style['landing-hero-panel-screen__panel--progress']]: true,
      }"
    >
      <div :class="$style['landing-hero-panel-screen__content']">
        <div :class="$style['landing-hero-panel-screen__flexible-container']">
          <LandingSectionHeader
            v-if="progressHeaderData"
            v-bind="progressHeaderData"
            :class="$style['landing-hero-panel-screen__header']"
            :is-inverse-text-color="!!isDark"
          />
          <LandingStepList
            v-bind="progressList"
            :class="$style['landing-hero-panel-screen__progress-list']"
          />
          <LandingSectionFooter
            v-if="progressFooterData"
            v-bind="progressFooterData"
            :class="$style['landing-hero-panel-screen__progress-footer']"
            :is-full-width-button="!!progressFooterData.secondButtonProps"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.landing-hero-panel-screen {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 24px 16px 60px;

  &__panel {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 24px 16px;
    background: var(--landingPanelBackgroundPrimary);
    border-radius: 16px;

    &--progress {
      background: var(--landingProgressPanelBackground);

      #{$self}__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }

  &__bg-image {
    display: none;
  }

  &__image {
    width: 100%;
  }

  &__flexible-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
  }

  &__progress-list {
    width: 100%;
    padding: 16px;
    background: var(--landingProgressListBackground);
    border-radius: 16px;
  }

  @include landingDesktop {
    gap: 16px;
    padding: 32px 0 0;

    &__panel {
      padding: calc(100% / 2.2) 0 0;
      background: transparent;
    }

    &__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      padding-left: 8%;
      background: var(--landingPanelBackgroundPrimary);
      border-radius: 16px;
    }

    &__bg-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: auto 100%;
    }

    &__flexible-container {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 54.33%;
      height: auto;
      border-radius: 16px;
    }

    &--full-thin {
      #{$self}__panel {
        padding: calc(100% / 2.4) 8% 0;
      }

      #{$self}__flexible-container {
        width: 45%;
      }

      #{$self}__bg-image {
        top: 30px;
        right: 8%;
        bottom: 30px;
      }
    }

    &--short-default {
      flex-direction: row;

      #{$self}__panel {
        width: 55%;
        padding: 0;
      }

      #{$self}__content {
        position: relative;
        height: 100%;
        padding: 36px 5.96%;
      }

      #{$self}__flexible-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      #{$self}__bg-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-size: auto 70%;
      }

      #{$self}__panel--progress {
        #{$self}__content {
          padding: 36px;
          background: var(--landingProgressPanelBackground);
        }
      }
    }
  }
}
</style>
