import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { AdditionalQueryStringParameters } from 'web/src/components/Iframe/VIframe/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { TwitterTimelineWidgetProperties } from 'web/src/modules/framed-app/components/TwitterWidget/enums';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { useTheme } from 'web/src/modules/theme/composables';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface UseTwitterTimelineCybersportStoreComposable {
  iframeWidgetName: Ref<IframeWidget>;
  iframeWidgetSrc: Ref<Optional<string>>;
  additionalQueryStringParameters: Ref<AdditionalQueryStringParameters>;
}

export function useTwitterTimelineCybersportStoreComposable(): UseTwitterTimelineCybersportStoreComposable {
  const { theme } = useTheme();
  const { locale } = useI18nLocale();

  const siteConfigStore = useSiteConfigStore();
  const timelineQuery = toRef(() => siteConfigStore.socialMediaTwitterUrl);

  const iframeWidgetName = ref(IframeWidget.TwitterTimeline);

  const iframeWidgetSrcRaw = useFramedWidgetUrl(iframeWidgetName.value);
  const iframeWidgetSrc = computed<Optional<string>>(() => (
    timelineQuery.value ? iframeWidgetSrcRaw.value : undefined
  ));

  const additionalQueryStringParameters = computed((): AdditionalQueryStringParameters => {
    if (!timelineQuery.value) {
      return [];
    }

    return [
      { [TwitterTimelineWidgetProperties.Theme]: theme.value },
      { [TwitterTimelineWidgetProperties.Query]: timelineQuery.value },
      { [TwitterTimelineWidgetProperties.Locale]: locale.value },
      { [TwitterTimelineWidgetProperties.Width]: process.env.VUE_APP_OS_WINDOWS ? '200' : '220' },
    ];
  });

  return {
    iframeWidgetName,
    iframeWidgetSrc,
    additionalQueryStringParameters,
  };
}
