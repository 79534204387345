import { logger } from '@leon-hub/logging';

export default function openUrl(
  url: string,
  target?: string,
  params?: Record<string, string>,
): void {
  try {
    let modifiedUrl = url;
    if (params && Object.keys(params).length > 0) {
      const queryString = new URLSearchParams(params).toString();
      modifiedUrl += url.includes('?') ? `&${queryString}` : `?${queryString}`;
    }

    window.open(modifiedUrl, target || '_blank');
  } catch (error) {
    logger.error(`Missing window: ${error}`);
  }
}
