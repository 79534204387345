const knownBooleanProps: string[] = [
  'disabled',
  'hidden',
  'isIgnoredByDisableCounter',
  'shortWidth',
  'autofocus',
  'isBirthday',
  'emitFilled',
  'readonly',
  'isYmDisableKeys',
  'clickableIcon',
  'open',
  'isCityType',
  'isCountrySelector',
  'disallowUserInput',
  // slott props
  'isLarge',
  'isHintHidden',
  'isLabelHidden',
  'hideValidation',
  'showSelectedIcon',
  'searchEnabled',
  'disableHoverFilter',
  'isMultiselectMode',
];

const knownNumberProps: string[] = [
  'legalAge',
  'size',
  'maxSymbols',
  'maxLength',
  'rowsMax',
  'rowsMin',
  'maximumFractionDigits',
  'expires',
];

const knownStringArrayProps: string[] = [
  'forbiddenSymbols',
];

export {
  knownBooleanProps,
  knownNumberProps,
  knownStringArrayProps,
};
