import type { AnyIconName } from '@leon-hub/icons';

import type { LandingButtonProps } from 'web/src/modules/landings/system/components/LandingButton/types';

export enum LandingSectionHeaderType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface LandingSectionFooterProps {
  firstButtonProps?: LandingButtonProps;
  secondButtonProps?: LandingButtonProps;
  paragraph?: { label: string; icon?: AnyIconName };
  isFullWidthButton?: boolean;
}

export interface LandingSectionHeaderProps {
  type?: LandingSectionHeaderType;
  title: string;
  label: string;
  isInverseTextColor?: boolean;
  chevronLabel?: string;
  maxLengthLabel?: number;
  isTitleBorderedAccent?: boolean;
}
