import type {
  Component,
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
  nextTick,
  onMounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type {
  SearchRoutePageEmits,
  SearchRoutePageProps,
} from 'web/src/modules/search/pages/SearchRoutePage/types';
import { SearchTab } from 'web/src/modules/search/enums';
import type { VSearchInputProps, VSearchInputRef } from 'web/src/components/SearchInput/VSearchInput/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { SearchResultContentRef } from 'web/src/modules/search/submodules/types';
import { useOnAppContentLoadedCallback } from 'web/src/modules/core/composables/content';
import SearchSportlineContent from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/SearchSportlineContent.vue';
import SlotResults from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/CasinoSearchContent.vue';

interface SearchRoutePageComposable {
  autofocus: Ref<boolean>;
  input: Ref<VSearchInputRef | undefined>;
  result: Ref<SearchResultContentRef | undefined>;
  searchInputProperties: ComputedRef<VSearchInputProps>;
  onInput: (value: string) => void;
  onClear: () => void;
  onFocus: () => Promise<void>;
  selectedTabIndex: Ref<SearchTab>;
  onActiveTabChanged: (index: SearchTab) => void;
  resultsComponent: ComputedRef<Component | undefined>;
  resultsComponentProps: ComputedRef<Record<string, boolean | undefined>>;
  setSearchText: (value: string) => void;
  emitItemClick: () => void;
}

export default function useSearchRoutePage(
  props: SearchRoutePageProps,
  emits: SearchRoutePageEmits,
): SearchRoutePageComposable {
  const { performOnLoad } = useOnAppContentLoadedCallback();

  const input = ref<VSearchInputRef>();
  const result = ref<SearchResultContentRef>();

  const autofocus = ref<boolean>(false);

  const { $translate } = useI18n();
  const router = useRouter();

  const selectedTabIndex = ref<SearchTab>(SearchTab.UNDEFINED);
  const searchText = ref<string>('');

  const searchInputProperties = computed<VSearchInputProps>(() => {
    let placeholder;

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED && selectedTabIndex.value === SearchTab.SPORTS) {
      placeholder = $translate('WEB2_PLACEHOLDER_SEARCH_EVENTS').value;
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && selectedTabIndex.value === SearchTab.SLOTS) {
      placeholder = $translate('WEB2_PLACEHOLDER_SEARCH_SLOTS').value;
    }

    return {
      placeholder,
      value: searchText.value,
    };
  });

  const resultsComponent = computed<Component | undefined>(() => {
    let component: Component | undefined;

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED && selectedTabIndex.value === SearchTab.SPORTS) {
      component = SearchSportlineContent;
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && selectedTabIndex.value === SearchTab.SLOTS) {
      component = SlotResults;
    }

    return component;
  });

  const resultsComponentProps = computed<Record<string, boolean | undefined>>(() => {
    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && selectedTabIndex.value === SearchTab.SLOTS) {
      return {
        shouldReplaceItemRoutes: props.shouldReplaceItemRoutes,
      };
    }

    return {};
  });

  async function init(): Promise<void> {
    if (props.defaultTabIndex) {
      selectedTabIndex.value = props.defaultTabIndex;
    } else if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
      selectedTabIndex.value = SearchTab.SPORTS;
    } else if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      selectedTabIndex.value = SearchTab.SLOTS;
    }

    await nextTick();
    onClear();

    if (!process.env.VUE_APP_OS_IOS) {
      performOnLoad(() => {
        autofocus.value = true;
      });
    }
  }

  async function onFocus(): Promise<void> {
    await nextTick();
    input.value?.focusInputElement();
  }

  function setSearchText(value: string): void {
    searchText.value = value;
  }

  function onInput(value: string): void {
    setSearchText(value);
    result.value?.onInput(value);
  }

  function onClear(): void {
    setSearchText('');
    result.value?.onInputClear();
  }

  function onActiveTabChanged(index: SearchTab): void {
    selectedTabIndex.value = index;
    onClear();
    void onFocus();

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && !props.shouldReplaceItemRoutes) {
      void router.replace({
        name: index === SearchTab.SLOTS
          ? CasinoRouteName.CASINO_SEARCH
          : RouteName.SEARCH,
      });
    }
  }

  function emitItemClick(): void {
    emits('item-click');
  }

  onMounted(init);

  return {
    autofocus,
    input,
    result,
    searchInputProperties,
    onInput,
    onClear,
    onFocus,
    selectedTabIndex,
    onActiveTabChanged,
    resultsComponent,
    resultsComponentProps,
    setSearchText,
    emitItemClick,
  };
}
