import { getBaseHeaders } from '@leon-hub/api';

import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';

function getCasHeaders(): Record<string, string> {
  const baseHeaders = getBaseHeaders();

  return Object.keys(baseHeaders).reduce((accumulator: Record<string, string>, headerName) => {
    if ([
      'X-APP-PLATFORM',
      'X-APP-LAYOUT',
      'X-APP-OS',
      'X-APP-STORE',
    ].includes(headerName)) {
      accumulator[headerName] = baseHeaders[headerName] || '';
    }

    return accumulator;
  }, {});
}

export default class CasRestApiClient extends ApplicationRestClient {
  constructor() {
    super({
      baseUrl: '',
      method: 'GET',
      headers: getCasHeaders(),
      origin: '',
    });
  }
}
