export default class FileUtils {
  public static extension(path: string): string {
    const pathArray = path.split('.');
    return pathArray.length > 1 ? (pathArray.pop() || '') : '';
  }

  public static download(url: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = url;
    link.target = '_blank';
    document.body.append(link);
    link.click();
    link.remove();
  }

  public static isImage(url: string): boolean {
    return /\.(jpeg|jpg|gif|png)$/.test(url);
  }

  public static blobToFile(theBlob: Blob, fileName?: string): File {
    return new File([theBlob], fileName || `${Date.now()}.png`, {
      lastModified: Date.now(),
      type: 'image/png',
    });
  }

  public static fileNameFromSrc(source: string): string {
    const filename = source.slice(Math.max(0, source.lastIndexOf('/') + 1));
    return filename.split('@')[0];
  }
}
