import type { LoyaltyCustomerLevel } from '@leon-hub/websocket';
import type { CasinoLevelSetting, getCasinoLoyaltyStatusQuery, Web2Image } from '@leon-hub/api-sdk';

import type { CircleColor } from 'web/src/components/CircularProgress/VCircularProgress/enums';

export interface UpdateCasinoLoyaltyStatusDataProperties {
  newProperties: Partial<LoyaltyCustomerLevel>;
}

export enum CasinoLoyaltyProgramIconSizes {
  DEFAULT = 'default',
  SIZE_24 = 'size-24',
  SIZE_32 = 'size-32',
  SIZE_36 = 'size-36',
  SIZE_42 = 'size-42',
  SIZE_68 = 'size-68',
  SIZE_80 = 'size-80',
  SIZE_86 = 'size-86',
  SIZE_92 = 'size-92',
  SIZE_94 = 'size-94',
  SIZE_96 = 'size-96',
  SIZE_112 = 'size-112',
  SIZE_120 = 'size-120',
  SIZE_132 = 'size-132',
  SIZE_136 = 'size-136',
  SIZE_160 = 'size-160',
  SIZE_180 = 'size-180',
  SIZE_200 = 'size-200',
  SIZE_230 = 'size-230',
}

export enum CasinoLoyaltyProgramProgressSizes {
  SIZE_42 = 'size-42',
  SIZE_92 = 'size-92',
  SIZE_94 = 'size-94',
  SIZE_112 = 'size-112',
}

export enum CasinoLoyaltyProgramLevelStatus {
  SUCCESS = 'success',
  PROCESS = 'process',
  LOCK = 'lock',
}

export enum CasinoLoyaltyProgramCardSizes {
  SIZE_116 = 'size-116',
  SIZE_124 = 'size-124',
}

export interface CasinoLoyaltyProgramIconProperties {
  imageSrc?: string;
  size?: CasinoLoyaltyProgramIconSizes;
  isInactive?: boolean;
  image?: Maybe<Web2Image>;
}

export interface CasinoLoyaltyCardProperties {
  title?: string;
  levelLabel?: string;
  imageSrc?: string;
  levelStatus?: CasinoLoyaltyProgramLevelStatus;
  currentValue?: number;
  total?: number;
  cardSize?: string;
}

export interface CasinoLoyaltyProgramProgressProperties {
  currentValue?: number;
  total?: number;
  imageSrc?: string;
  imageSrcNextLevel?: string;
  size?: CasinoLoyaltyProgramProgressSizes;
  hasShadow?: boolean;
  isInactive?: boolean;
  progress?: number;
  isHideProgress?: boolean;
  showImageNextLevel?: boolean;
  image?: Web2Image | null;
  circleColor?: CircleColor;
}

export interface CasinoLoyaltyProgramLevelGameProperties {
  progress?: number;
  currentValue?: number;
  total?: number;
  imageSrc?: string;
  levelTitle?: string;
  multiplier?: string;
  backgroundColor?: string;
}

export interface CasinoLoyaltyProgramListProperties {
  bonusAmount?: number;
  multiplier?: number;
  freespin?: number;
  bonusPointsCount?: number;
  status?: CasinoLoyaltyProgramLevelStatus;
  freespinAvailable?: boolean;
  freespinSpent?: boolean;
  freespinGameId?: string;
  freespinProvider?: string;
}

export interface CasinoLoyaltyProgramCurrentLevelCardProperties {
  initLevel?: boolean;
  currentValue?: number;
  total?: number;
  imageSrc?: string;
  levelTitle?: string;
  nextLevelTitle?: string;
  multiplier?: string;
  backgroundColor?: string;
}

export type CasinoLevelSettingWithStatus = CasinoLevelSetting & {
  status: CasinoLoyaltyProgramLevelStatus;
  progress: number;
};

export type CasinoLoyaltyStatusDocument
  = Exclude<getCasinoLoyaltyStatusQuery['queries']['bonuses']['getCasinoLoyaltyStatus']['data'], null>;

export type CasinoLoyaltyLevelSetting = CasinoLoyaltyStatusDocument['levelSettings'][0];
