import type { Ref } from 'vue';
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { BusEvent, useBusSafeSubscribe, useEventsBus } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useLifecycleResizeObserver } from '@leon-hub/vue-utils';

import {
  useRootStore,
  useScrollStore,
} from 'web/src/modules/core/store';

const disableScrollClassName = 'disable-scroll';
const phoneFixedStyleClassName = 'fixed-scroll-styles';

export interface LayoutScrollComposable {
  mainContent: Ref<HTMLElement | undefined>;
  restoreScrollPosition(): Promise<void>;
}

export type ScrollTarget = HTMLElement | Window | null | undefined;

export default function useLayoutScroll<T extends ScrollTarget>(
  content: Ref<T>,
): LayoutScrollComposable {
  const mainContent = ref<HTMLElement>();

  const bus = useEventsBus();
  const { setHasScrollableContent, setScrollTop } = useScrollStore();

  let lastScrollTop = 0;

  function getContentScrollElement(): Exclude<ScrollTarget, Window> {
    return content.value instanceof Window ? window.document.documentElement : content.value;
  }

  function handleScroll(): void {
    const target = getContentScrollElement();
    if (!target) {
      return;
    }

    setScrollTop(target.scrollTop);

    bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL, {
      scrollTop: target.scrollTop,
      offsetHeight: target.offsetHeight,
      scrollHeight: target.scrollHeight,
    });
  }

  function scrollContent(top: number, smooth = false): void {
    try {
      content.value?.scrollTo({
        left: 0,
        top: top === 0 ? -1 : top,
        behavior: smooth ? 'smooth' : 'auto',
      });
    } catch {}
  }

  const disabledScrollCounters: Record<string, number> = {};

  function fixPhoneScroll(scrollElement: ScrollTarget): void {
    if (scrollElement && scrollElement instanceof HTMLElement) {
      lastScrollTop = scrollElement.scrollTop;
      scrollElement.classList.add(phoneFixedStyleClassName);

      scrollElement.style.top = `-${lastScrollTop}px`;
    }
  }

  function releasePhoneScroll(scrollElement: ScrollTarget): void {
    if (scrollElement && scrollElement instanceof HTMLElement) {
      scrollElement.classList.remove(phoneFixedStyleClassName);
      scrollElement.style.removeProperty('top');
      scrollElement.scrollTo(0, lastScrollTop);
    }
  }

  function disableScroll({ reason }: { reason: string }): void {
    try {
      if (!disabledScrollCounters[reason]) {
        disabledScrollCounters[reason] = 0;
      }

      disabledScrollCounters[reason] += 1;

      const contentScroll = getContentScrollElement();
      contentScroll?.classList.add(disableScrollClassName);
      if (process.env.VUE_APP_LAYOUT_PHONE) {
        fixPhoneScroll(contentScroll);
      }
    } catch {}
  }

  function enableScroll({ reason }: { reason: string }): void {
    try {
      if (disabledScrollCounters[reason]) {
        disabledScrollCounters[reason] -= 1;

        if (disabledScrollCounters[reason] <= 0) {
          delete disabledScrollCounters[reason];
        }
      }

      const totalCounter = Object.values(disabledScrollCounters).reduce((accumulator, item) => (
        accumulator + item
      ), 0);

      if (!totalCounter) {
        const contentScroll = getContentScrollElement();
        contentScroll?.classList.remove(disableScrollClassName);
        if (process.env.VUE_APP_LAYOUT_PHONE) {
          releasePhoneScroll(contentScroll);
        }
      }
    } catch {}
  }

  async function restoreScrollPosition(): Promise<void> {
    const nextTopValue = window.history.state?.scrollTop || 0;

    // instant setup scroll position for the same layout
    content.value?.scrollTo({ top: nextTopValue });

    if (nextTopValue > 0) {
      await nextTick();
      await nextAnimationFrame();
      // await updating position after change layout
      content.value?.scrollTo({ top: nextTopValue });
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      lastScrollTop = nextTopValue;
    }
  }

  onMounted(() => {
    content.value?.addEventListener('scroll', handleScroll);
  });

  onBeforeUnmount(() => {
    content.value?.removeEventListener('scroll', handleScroll);
  });

  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, ({ smooth }) => {
    scrollContent(0, smooth);
  });

  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SET_SCROLL, ({ scrollTop, smooth }) => {
    scrollContent(scrollTop, smooth);
  });

  useBusSafeSubscribe(BusEvent.SCROLL_TO_ELEMENT_ID, ({ id, inModal, smooth }) => {
    if (inModal) {
      return;
    }

    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    scrollContent(element.offsetTop, smooth);
  });

  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, disableScroll);
  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, enableScroll);

  const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');
  const route = useRoute();
  async function updateScrollContent(): Promise<void> {
    await nextTick();
    const target = getContentScrollElement();

    if (target) {
      setHasScrollableContent(target.offsetHeight < target.scrollHeight);
    }
  }

  watch(() => route.path, updateScrollContent);

  watch(isAppMainContentLoaded, updateScrollContent, { immediate: true });
  useLifecycleResizeObserver(mainContent, updateScrollContent);

  return {
    mainContent,
    restoreScrollPosition,
  };
}
