import { ScriptElement } from '@leon-hub/utils';

export async function getSessionID(sdkUrl: string): Promise<string> {
  const scriptElement = new ScriptElement({ src: sdkUrl });
  await scriptElement.addScript();
  // @ts-expect-error no types
  const deviceResult = await window.getDeviceResult();

  if (deviceResult.result)
    return deviceResult.result.session_id;

  throw new Error(`Unexpected api response:\n${JSON.stringify(deviceResult)}`);
}
