import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { useDebounce } from '@leon-hub/debounce';

import type { BaseInputEmits, VInputInputEvent } from 'web/src/components/Input';
import { InputEventType } from 'web/src/components/Input/enums';
import type { AddressPrediction } from 'web/src/modules/address/services/api/types';

import type { AutocompleteAddressProps } from '../types/AutocompleteAddressProps';
import useAddressSearch from './useAddressSearch';

interface UseAutocompleteAddress {
  onInput(event: VInputInputEvent): void;
  onChange(): void;
  onBlur(): void;
  onFocus(): void;
  onClear(): void;
  selectPrediction(prediction: AddressPrediction): void;
  showPredictions: ComputedRef<boolean>;
  predictions: Ref<AddressPrediction[]>;
  inputValue: Ref<string>;
  preselectedIndex: Ref<number>;
  onKeyDown(event: KeyboardEvent): void;
  setPreselectedIndex(index: number): void;
  isEmptyResultBlockShown: Ref<boolean>;
  isPendingSearch: Ref<boolean>;
}

export default function useAutocompleteAddress(
  props: AutocompleteAddressProps,
  emit: BaseInputEmits,
): UseAutocompleteAddress {
  const { findAddressByQuery } = useAddressSearch();

  const inputValue = ref<string>(props.value || '');

  const isPreselected = ref<boolean>(false);

  const predictions = ref<AddressPrediction[]>([]);

  const isFocused = ref<boolean>(false);

  const isPendingSearch = ref<boolean>(true);

  const preselectedIndex = ref<number>(-1);

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: props.disallowUserInput && !isPreselected.value ? '' : inputValue.value,
      name: props.name || '',
    },
  }));

  const placeTypes = computed<string[]>(() => {
    /**
     * @example ['(cities)'] - search only cities
     * @example ['street_address'] - street address
     * @see https://developers.google.com/maps/documentation/javascript/supported_types
     * LEONWEB-9819 - only cities or address is allowed
     * address is default, (cities) is enabled by isCityType prop
     */
    return props.isCityType ? [('(cities)')] : ['address'];
  });

  const setPreselectedIndex = (index = -1): void => {
    preselectedIndex.value = predictions.value[index] ? index : -1;
  };

  watch(() => props.value, (value) => {
    inputValue.value = value || '';
  });

  const resetPredictionsOnRelatedDataChange = (newValue: string, oldValue: string): void => {
    if (newValue !== oldValue) {
      setPreselectedIndex(-1);
      predictions.value = [];
    }
  };

  watch(() => props.countryCode, resetPredictionsOnRelatedDataChange);
  watch(() => props.selectedCity, resetPredictionsOnRelatedDataChange);

  const emitFocus = (): void => {
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const emitBlur = (): void => {
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  const onChange = (): void => {
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const emitInput = (): void => {
    emit(InputEventType.INPUT, eventToEmit.value);
  };

  const showPredictions = computed<boolean>(() => !!(isFocused.value && (predictions.value.length || props.disallowUserInput)));

  const isEmptyResultBlockShown = computed<boolean>(() => !!props.disallowUserInput && !predictions.value.length);

  const searchAddress = async (query: string): Promise<void> => {
    const computedQuery = query && props.selectedCity ? `${props.selectedCity}, ${query}` : query;
    isPendingSearch.value = true;
    predictions.value = await findAddressByQuery({
      address: computedQuery,
      countryCode: props.countryCode,
      types: placeTypes.value,
    });
    isPendingSearch.value = false;
  };

  const debouncedAddressSearch = useDebounce((query: string) => {
    void searchAddress(query);
  }, 500);

  const onFocus = (): void => {
    isFocused.value = true;
    emitFocus();
    if (inputValue.value && !predictions.value.length) {
      void searchAddress(inputValue.value);
    }
  };

  const onBlur = (): void => {
    isFocused.value = false;
    setPreselectedIndex(-1);
    emitBlur();
    if (props.disallowUserInput && !isPreselected.value) {
      inputValue.value = '';
      emitInput();
    }
  };

  const onInput = (event: VInputInputEvent): void => {
    const { value } = event.target;
    inputValue.value = value;
    isPreselected.value = false;
    if (!props.disallowUserInput) {
      emitInput();
    }
    void debouncedAddressSearch(value);
  };

  const onClear = (): void => {
    predictions.value = [];
    inputValue.value = '';
    isPreselected.value = false;
    setPreselectedIndex(-1);
    emitInput();
  };

  const selectPrediction = (prediction: AddressPrediction): void => {
    predictions.value = [];
    setPreselectedIndex(-1);
    inputValue.value = prediction.structured_formatting.main_text;
    isPreselected.value = true;
    emitInput();
  };

  const preselectByIndex = (index = -1): void => {
    const prediction = predictions.value[index];
    if (prediction) {
      selectPrediction(prediction);
    }
  };

  const movePreselectedIndex = (moveDown = false): void => {
    let nextIndex = moveDown ? preselectedIndex.value + 1 : preselectedIndex.value - 1;
    if (nextIndex >= predictions.value.length) {
      nextIndex = 0;
    }
    if (nextIndex < 0) {
      nextIndex = predictions.value.length - 1;
    }
    setPreselectedIndex(nextIndex);
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    const { key } = event;
    if ((predictions.value.length)) {
      if (key === 'Enter' && preselectedIndex.value > -1) {
        event.preventDefault();
        preselectByIndex(preselectedIndex.value);
        return;
      }
      if (key === 'ArrowDown' || key === 'ArrowUp') {
        event.preventDefault();
        movePreselectedIndex(key === 'ArrowDown');
      }
    }
  };

  return {
    inputValue,
    predictions,
    onInput,
    onChange,
    onClear,
    onBlur,
    onFocus,
    showPredictions,
    selectPrediction,
    preselectedIndex,
    isEmptyResultBlockShown,
    isPendingSearch,
    onKeyDown,
    setPreselectedIndex,
  };
}
