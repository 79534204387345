import { SocketWrapper, SocketEmitter, isWSSupported } from "@leon-hub/websocket";
import { defineAppModule } from "@leon-hub/app-module";
import { getLocationOrigin } from "@leon-hub/service-locator-env";
import throttle from "lodash/throttle";
import { normalizeError } from "@leon-hub/errors";
class ActivityReporter {
  constructor(options) {
    this.options = options;
    this.socketWrapper = new SocketWrapper({
      openTimeout: 2e4,
      url: this.options.url
    });
    this.emitter = new SocketEmitter(this.socketWrapper);
  }
  start() {
    const { bus } = this.options.api;
    const onClick = throttle(() => {
      this.emitter.emit("customer_activity", {
        type: "click"
        // eslint-disable-next-line no-console
      }).catch((err) => bus.emit("log:error", normalizeError(err)));
    }, this.options.activityThrottle, { trailing: false });
    const onKeyUp = throttle(() => {
      this.emitter.emit("customer_activity", {
        type: "keyup"
        // eslint-disable-next-line no-console
      }).catch((err) => bus.emit("log:error", normalizeError(err)));
    }, this.options.activityThrottle, { trailing: false });
    document.addEventListener("click", onClick, true);
    document.addEventListener("keyup", onKeyUp, true);
    window.addEventListener("beforeunload", () => {
      this.socketWrapper.detach();
    });
  }
}
const useActivityReporterModule = /* @__PURE__ */ defineAppModule("activity-reporter", (api) => {
  const urlPath = process.env.VUE_APP_WEB_SOCKET_PUBLIC_URL;
  let reporter;
  if (urlPath && isWSSupported()) {
    const url = `${getLocationOrigin().replace(/http(s)?:/, "ws$1:")}${urlPath}`;
    reporter = new ActivityReporter({
      activityThrottle: 1e4,
      url,
      api
    });
  }
  return {
    async start() {
      return Promise.resolve(reporter == null ? void 0 : reporter.start());
    }
  };
});
export {
  useActivityReporterModule as useModule
};
