import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLiveChatPushNotificationsStore = defineStore('live-chat-push-notifications', () => {
  const isChatOpenedByPush = ref(false);

  function setChatOpenedState(value: boolean): void {
    isChatOpenedByPush.value = value;
  }

  return {
    isChatOpenedByPush,
    setChatOpenedState,
  };
});
