<script lang="ts" setup>
import { ref } from 'vue';

import OverlayType from 'web/src/components/Modal/VOverlay/enums/OverlayType';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import { ModalWidth, ModalHeight } from 'web/src/components/Modal/enums';
import type { ModalRef } from 'web/src/components/Modal/types';

import { useDefaultModal } from './composables';
import ModalWindow from '../ModalWindow/ModalWindow.vue';
import type {
  DefaultModalProps,
  DefaultModalEmits,
  DefaultModalRef,
  DefaultModalSlots,
} from './types';

const props = withDefaults(defineProps<DefaultModalProps>(), {
  // eslint-disable-next-line vue/no-boolean-default
  isCloseAllowed: true,
  // eslint-disable-next-line vue/no-boolean-default
  isOverlayCloseAllowed: true,
  // eslint-disable-next-line vue/no-boolean-default
  isOverlayVisible: true,
  width: ModalWidth.DEFAULT,
  height: ModalHeight.DEFAULT,
  // eslint-disable-next-line vue/no-boolean-default
  isCentered: true,
  // eslint-disable-next-line vue/no-boolean-default
  isFullHeightCentered: true,
  overlayType: OverlayType.BLUR_L1,
});

const emit = defineEmits<DefaultModalEmits>();
const modalWindow = ref<DefaultModalRef>();

const {
  outerModalContainerId,
  containerId,
  modalWindowProps,
  onOverlayClick,
  onClose,
} = useDefaultModal(props, emit);

function emitButtonClick(value: number) {
  emit('button-click', value);
}

function emitScrollReachedBottom() {
  emit('scroll-reached-bottom');
}

function scrollTop(): void {
  modalWindow.value?.scrollTop();
}

function scrollToElement(element: HTMLElement | null): void {
  modalWindow.value?.scrollToElement(element);
}

function scrollUpdate(): void {
  modalWindow.value?.scrollUpdate();
}

defineExpose<ModalRef>({
  scrollTop,
  scrollToElement,
  scrollUpdate,
});

defineSlots<DefaultModalSlots>();
</script>

<template>
  <div v-auto-id="'DefaultModal'"
    :id="containerId"
    v-data-test="{
      el: 'modal',
      type: iconKind || 'unknown',
      modalId: `modal-${dataTestId}` || '',
    }"
    :class="{
      [$style['modal']]: true,
      [$style['modal--alert']]: props.isAlert,
      [$style['modal--centered']]: props.isCentered,
      [$style['modal--min-height']]: props.hasMinHeight,
      [$style['modal--has-inner']]: props.hasInnerModal && !props.hasInnerModalFullHeight,
      [$style[`modal--width-${props.width}`]]: true,
      [$style[`modal--height-${props.height}`]]: true,
      [$style['modal--full-height']]: props.isFullHeight,
      [$style['modal--profile']]: props.isProfileLayout,
      [$style['modal--full-screen']]: props.isFullScreen,
      [$style['modal--has-custom-bg']]: !!props.customBackground,
      [$style['modal--is-padding-top-big']]: props.isPaddingTopBig,
    }"
  >
    <Teleport
      v-if="isInnerModal"
      :to="`#${outerModalContainerId}`"
      :disabled="!isInnerModal"
    >
      <div
        v-if="isInnerModal"
        :class="$style['modal__inner-overlay']"
        @click="onOverlayClick"
      />
    </Teleport>
    <VOverlay
      v-if="isOverlayVisible"
      :type="overlayType"
      :class="$style['modal__overlay']"
      @click="onOverlayClick"
    />
    <ModalWindow
      v-bind="modalWindowProps"
      :id="innerModalContainerId"
      ref="modalWindow"
      :class="$style['modal__window']"
      :style="{'background': customBackground}"
      @close="onClose"
      @button-click="emitButtonClick"
      @scroll-reached-bottom="emitScrollReachedBottom"
    >
      <template
        v-if="$slots.default"
        #default
      >
        <slot />
      </template>
      <template
        v-if="$slots.header"
        #header
      >
        <slot name="header" />
      </template>
      <template
        v-if="$slots['inner-content']"
        #inner-content
      >
        <slot name="inner-content" />
      </template>
      <template
        v-else-if="$slots.icon"
        #icon
      >
        <slot name="icon" />
      </template>
      <template
        v-if="$slots.content"
        #content
      >
        <slot name="content" />
      </template>
      <template
        v-if="$slots.footer"
        #footer
      >
        <slot name="footer" />
      </template>
    </ModalWindow>
  </div>
</template>

<style lang="scss" module>
@import '../common';

@keyframes modal-slide-up {
  0% {
    transform: translate3d(0, 50%, 0);
  }

  100% {
    transform: translateZ(0);
  }
}

.modal {
  $self: &;
  $widths: (
    default: 100%,
    small-minus: 320px,
    small: 375px,
    medium: 480px,
    medium-plus: 540px,
    big: 627px,
    large: 720px,
  );
  $heights: (
    default: auto,
    tiny: 248px,
    small-minus: 356px,
    small: 396px,
    normal: 456px,
    normal-plus: 464px,
    medium: 480px,
    big: 572px,
    extra: 584px,
    extra-plus: 588px,
    large: 600px,
    large-plus: 620px,
    max: 724px,
  );

  @include z-index(modal);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  > #{$self}__window {
    @include z-index(base);

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    will-change: transform;

    @include is-app-layout-desktop {
      max-height: calc(100vh - #{$bodyPaddingBottomForModal});
    }
  }

  &--min-height {
    > #{$self}__window {
      @include is-app-layout-desktop {
        min-height: get($heights, tiny);
      }
    }
  }

  &--has-inner {
    > #{$self}__window {
      @include is-app-layout-desktop {
        min-height: get($heights, small);
      }
    }
  }

  &--alert {
    align-items: flex-start;
    justify-content: center;
    padding-top: 56px;

    &#{$self}--centered {
      align-items: center;
      padding-top: 0;
    }

    > #{$self}__window {
      position: relative;
      width: 100%;
      min-width: 360px;
      max-width: get($widths, default);
    }
  }

  &--profile {
    .modal-window--inner-modal {
      box-shadow: 0 8px 16px var(--ModalShadow);
    }

    .modal-window--full-height {
      border-top-left-radius: 0;
      box-shadow: none;
    }
  }

  &--has-custom-bg {
    .modal-window--full-height {
      border-top-left-radius: 0;
      box-shadow: none;
    }
  }

  &--is-padding-top-big {
    padding-top: 96px;
  }

  &:not(#{$self}--full-height) {
    --StatusbarHeight: 0px;
  }

  &--full-height {
    > #{$self}__window {
      top: 0;
    }
  }

  &--full-screen {
    > #{$self}__window {
      @include is-app-layout-desktop {
        max-height: 100vh;
      }
    }
  }

  &__inner-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }

  // Animations
  &__window {
    :global(.modal-slide-up-enter-active) :local & {
      @include is-app-layout-phone {
        animation-name: modal-slide-up;
        animation-duration: $animationModalSlidesUpDuration;
      }
    }

    :global(.modal-slide-up-leave-active) :local & {
      @include is-app-layout-phone {
        animation-name: modal-slide-up;
        animation-duration: $animationModalSlidesUpLeaveDuration;
        animation-direction: reverse;
      }
    }
  }

  & + & {
    #{$self}__overlay {
      background-color: transparent;
    }
  }

  @include is-app-layout-desktop {
    &:not(#{$self}--alert) {
      position: absolute;
    }
  }

  @include is-app-layout-desktop {
    &--width {
      @each $name, $width in $widths {
        @if $name != default {
          &-#{$name} {
            > #{$self}__window {
              max-width: $width;
            }
          }
        }
      }
    }

    &--height {
      @each $name, $height in $heights {
        @if $name != default {
          &-#{$name} {
            > #{$self}__window:not(.modal-window--full-height) {
              height: $height;
            }
          }
        }
      }
    }
  }
}
</style>
