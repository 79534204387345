import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';
import { customerBetTypeUndefined } from '@leon-hub/api-sdk';
import { getLocationHost, getLocationHref, getUserAgent } from '@leon-hub/service-locator-env';

import type { CommonLogData } from 'web/src/modules/analytics/composables/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useRouterStore } from 'web/src/modules/core/store';
import { useCountryStore } from 'web/src/modules/country/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useTheme } from 'web/src/modules/theme/composables';

export interface UseCommonAnalyticsData {
  commonLogData: Ref<CommonLogData>;
}

export function useCommonAnalyticsData(): UseCommonAnalyticsData {
  const { isLoggedIn } = useIsLoggedIn();
  const { theme } = useTheme();

  const customerDataStore = useCustomerDataStore();
  const routerStore = useRouterStore();

  const customerId = toRef(() => customerDataStore.login);
  const isVip = toRef(() => customerDataStore.isVip);
  const customerBetType = toRef(() => customerDataStore.customerBetType);
  const customerVipStatus = toRef(() => customerDataStore.customerData?.vipStatus);
  const currentRouteName = toRef(() => routerStore.currentRouteName);
  const previousRouteUrl = toRef(() => routerStore.previousRouteUrl);
  const previousRouteName = toRef(() => routerStore.previousRouteName);
  const countryCode = toRef(useCountryStore(), 'countryCode');

  return {
    commonLogData: computed<CommonLogData>(() => ({
      customer_id: customerId.value,
      customer_status: isLoggedIn.value ? 'logged_in' : 'logged_out',
      vs_lw: isVip.value,
      full_url: getLocationHref(),
      country: countryCode.value,
      device_type: getUserAgent(),
      platform_type: process.env.VUE_APP_PLATFORM_CORDOVA ? 'cordova' : 'web',
      theme: theme.value as ITheme,
      event_timestamp: Date.now(),
      customer_bet_type: customerBetType.value ?? customerBetTypeUndefined,
      customer_type: customerVipStatus.value ?? 0,
      web_version: process.env.VUE_APP_VERSION,
      previous_page_url: previousRouteUrl.value ?? '',
      main_domain: getLocationHost(),
      lead_section: String(previousRouteName.value) ?? '',
      event_category: String(currentRouteName.value) ?? '',
    })),
  };
}
