import { initializeApp } from 'firebase/app';
import type { Messaging } from 'firebase/messaging';
import {
  getMessaging,
  getToken as getTokenFirebase,
  isSupported as isFirebaseSupported,
  onMessage,
} from 'firebase/messaging';

import { assert } from '@leon-hub/guards';

interface FirebaseSettings {
  apiKey: string;
  projectId: string;
  senderId: string;
  appId: string;
  measurementId: string;
  vapidKey: string;
}

let isInitialized = false;
let vapidKey = '';
let messaging: Messaging | null = null;
let swRegistration: ServiceWorkerRegistration | undefined;

export interface UseFirebaseMessaging {
  init(settings: FirebaseSettings): Promise<void>;
  getToken(): Promise<string>;
  isSupported(): Promise<boolean>;
}

export default function useFirebaseMessaging(): UseFirebaseMessaging {
  async function isSupported(): Promise<boolean> {
    if (process.env.VUE_APP_RENDERING_SSR || process.env.VUE_APP_PLATFORM_CORDOVA || !window || !navigator
      || !navigator.serviceWorker || !window.Notification || window.Notification.permission === 'denied') {
      return false;
    }

    try {
      return await isFirebaseSupported();
    } catch {
      return false;
    }
  }

  async function init(settings: FirebaseSettings): Promise<void> {
    if (isInitialized
      || !settings.apiKey
      || !settings.projectId
      || !settings.senderId
      || !settings.appId
      || !settings.measurementId
      || !settings.vapidKey) {
      return;
    }

    if (!await isSupported()) {
      return;
    }

    try {
      const app = initializeApp({
        apiKey: settings.apiKey,
        authDomain: `${settings.projectId}.firebaseapp.com`,
        databaseURL: `https://${settings.projectId}.firebaseio.com`,
        projectId: settings.projectId,
        storageBucket: `${settings.projectId}.appspot.com`,
        messagingSenderId: settings.senderId,
        appId: settings.appId,
        measurementId: settings.measurementId,
      });
      vapidKey = settings.vapidKey;

      swRegistration = await navigator.serviceWorker.register(
        // eslint-disable-next-line max-len
        `/scripts/firebase/sw.firebase.js?apiKey=${settings.apiKey}&projectId=${settings.projectId}&senderId=${settings.senderId}&appId=${settings.appId}&measurementId=${settings.measurementId}`,
      );

      messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        if (payload.notification) {
          void swRegistration?.showNotification(payload.notification.title || '', {
            ...payload.notification,
            data: payload.data,
            icon: payload.data?.icon,
          });
        }
      });

      isInitialized = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('[Firebase] Unable to initialize app', error);
    }
  }

  function checkInitialized(): void {
    if (!isInitialized) {
      throw new Error('Please initialize Firebase api first');
    }
  }

  async function getToken(): Promise<string> {
    checkInitialized();
    assert(messaging);
    await Notification.requestPermission();
    return getTokenFirebase(messaging, {
      vapidKey,
      serviceWorkerRegistration: swRegistration,
    });
  }

  return {
    init,
    isSupported,
    getToken,
  };
}
