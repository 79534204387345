export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch {
    return false;
  }
}

export function isCookiesDisabled(): boolean {
  return !!process.env.VUE_APP_RENDERING_CSR && navigator && !navigator.cookieEnabled;
}
