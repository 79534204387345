import type { RouteLocationNormalized } from 'vue-router';

import type {
  IHomePageType,
  ILobbyType,
} from '@leon-hub/api-sdk';
import {
  homePageTypeLiveGames,
  lobbyTypeAll,
  lobbyTypeFastgames,
  lobbyTypeLiveCasino,
  lobbyTypeLiveGamePage,
  lobbyTypeSearch,
  lobbyTypeSlots,
  lobbyTypeSlotsGamePage,
} from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

export default function getLobbyTypeByRouteName(
  {
    routeName,
    homePageType,
  } : {
    routeName?: RouteLocationNormalized['name'];
    homePageType: IHomePageType;
  },
): ILobbyType {
  if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    return lobbyTypeFastgames;
  }

  switch (routeName) {
    case CasinoRouteName.CASINO_LIVE_LOBBY:
    case CasinoRouteName.CASINO_LIVE_GROUPS:
      return lobbyTypeLiveCasino;
    case CasinoRouteName.CASINO_LIVE_GAME:
      return lobbyTypeLiveGamePage;
    case CasinoRouteName.CASINO_GAME:
      return lobbyTypeSlotsGamePage;
    case CasinoRouteName.CASINO_SEARCH:
      return lobbyTypeSearch;
    case RouteName.QUICK_BETS:
    case RouteName.QUICK_BETS_DETAILS:
      return lobbyTypeFastgames;
    case RouteName.HOME:
      if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        return lobbyTypeAll;
      }

      if (homePageType === homePageTypeLiveGames) {
        return lobbyTypeLiveCasino;
      }

      return lobbyTypeSlots;
    default:
      return lobbyTypeSlots;
  }
}
