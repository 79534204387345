import { toNumber, toPattern } from '@leon-hub/vanilla-masker';

function reverseInputDate(value: string): string {
  return value.split('-').reverse().join('-');
}

export function formatInputStringToInputDate(value: string): string {
  return value ? reverseInputDate(toPattern(value, '99-99-9999')) : value;
}

export function formatInputDateToInputString(value: string): string {
  return value ? toNumber(reverseInputDate(value)) : value;
}
