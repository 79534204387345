import type { AnyIconName } from '@leon-hub/icons';

export enum StepStatus {
  STEP_WAITING = 'step-waiting',
  STEP_SUCCESS = 'step-success',
}
export interface LandingStepStatusProp {
  label: string;
  iconName: AnyIconName;
}
export enum StatusProgressType {
  DEFAULT = 'default',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
}

export interface LandingStatusProgressProp {
  type: StatusProgressType;
  isLast: boolean;
  label?: string;
}

export interface LandingStepProps {
  type: StatusProgressType;
  title: string;
  label: string;
  numberLabel?: string;
  progressLabel?: string;
  isLast?: boolean;
}

export interface LandingStepListProps {
  list: LandingStepProps[];
}
