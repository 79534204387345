import type {
  MetaInfo,
  MetaPropertyWithName,
  ScriptMetaProperty,
  ScriptMetaPropertyTemplate,
} from '@leon-hub/routing-config';
import { logger } from '@leon-hub/logging';
import { isArray } from '@leon-hub/guards';
import {
  isMetaPropertyWithName,
  isScriptMetaProperty,
  isTitleMetaProperty,
} from '@leon-hub/routing-config';

export function getMetaTitleFromMetaInfo(metaInfoConfig?: MetaInfo): Optional<string> {
  const config = metaInfoConfig?.title || [];
  const [title] = isArray(config) ? config : [config];
  return isTitleMetaProperty(title) ? title.content : title;
}

export function getMetaTagsFromMetaInfo(metaInfoConfig?: MetaInfo): MetaPropertyWithName[] {
  return (metaInfoConfig?.meta ?? [])
    .filter((value: unknown): value is MetaPropertyWithName => {
      if (isMetaPropertyWithName(value)) { return true; }
      logger.warn('Unexpected meta property value', value);
      return false;
    });
}

export function getMetaScriptsFromMetaInfo(metaInfoConfig?: MetaInfo): ScriptMetaProperty[] {
  return (metaInfoConfig?.script ?? [])
    .filter((value: unknown): value is ScriptMetaProperty => {
      if (isScriptMetaProperty(value)) { return !value.template; }
      logger.warn('Unexpected meta script property value', value);
      return false;
    });
}

export function getMetaScriptsTemplatesFromMetaInfo(
  metaInfoConfig?: MetaInfo,
): ScriptMetaPropertyTemplate[] {
  return (metaInfoConfig?.script ?? [])
    .filter((value: unknown): value is ScriptMetaPropertyTemplate => {
      if (isScriptMetaProperty(value)) { return !!value.template; }
      logger.warn('Unexpected meta script property value', value);
      return false;
    });
}
