import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';

import isLiveEgsRoute from './isLiveEgsRoute';

export default function getDefaultLobbyLocationByRouteName(route: RouteLocationNormalized): RouteLocationRaw {
  if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    return {
      name: RouteName.HOME,
    };
  }

  if (isLiveEgsRoute(route)) {
    return {
      name: CasinoRouteName.CASINO_LIVE_LOBBY,
    };
  }

  if (route.name === CasinoRouteName.CASINO_LOBBY) {
    return {
      name: CasinoRouteName.CASINO_LOBBY,
    };
  }

  if (route.name === RouteName.QUICK_BETS) {
    return {
      name: RouteName.QUICK_BETS,
    };
  }

  return {
    name: RouteName.HOME,
  };
}
