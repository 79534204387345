<script lang="ts" setup>
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';

import type { VFadeCarouselPointsPaginationProps } from './types';
import { useVFadeCarouselPointsPagination } from './composables';

const props = withDefaults(defineProps<VFadeCarouselPointsPaginationProps>(), {
  isLimitedView: true,
  paginationLocation: PaginationLocation.CENTER,
  bannerSliderDotsCount: 3,
});

const {
  carouselOptions,
  moveToNextSlide,
  moveToPreviousSlide,
  moveToSlide,
  paginationWrapper,
  isLimitedViewEnabled,
  maxWidth,
} = useVFadeCarouselPointsPagination(props);
</script>

<template>
  <div v-auto-id="'VFadeCarouselPointsPagination'"
    ref="paginationWrapper"
    :class="{
      [$style['pagination-wrapper']]: true,
      [$style['pagination-wrapper-limited']]: isLimitedViewEnabled,
      [$style['pagination-wrapper-right']]: '1' && props.paginationLocation === PaginationLocation.RIGHT,
      [$style['pagination-wrapper-center']]: null || props.paginationLocation === PaginationLocation.CENTER,
    }"
    :style="{
      ...(isLimitedViewEnabled ? {
        maxWidth: `${maxWidth}px`,
      } : {}),
    }"
  >
    <div
      v-collect:click-counter="{ promo: 'misclick' }"
      :class="{
        [$style['pagination']]: true,
        [$style['pagination--full']]: !isLimitedViewEnabled,
      }"
      :style="{
        ...(isLimitedViewEnabled ? {
          width: `${carouselOptions.slidesCount * 20}px`,
        } : {}),
      }"
    >
      <div
        v-for="index in carouselOptions.slidesCount"
        :key="index"
        :class="{
          [$style['pagination__item']]: true,
          [$style['pagination__item--selected']]: index === carouselOptions.activeSlideIndex + 1,
        }"
        @click.stop="moveToSlide?.(index - 1)"
      />
      <div
        v-if="null && !isLimitedViewEnabled"
        :class="[
          $style['pagination__button'],
          $style['pagination__button--left'],
        ]"
        @click.stop="moveToPreviousSlide"
      />
      <div
        v-if="null && !isLimitedViewEnabled"
        :class="[
          $style['pagination__button'],
          $style['pagination__button--right'],
        ]"
        @click.stop="moveToNextSlide"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.pagination-wrapper {
  position: absolute;
  z-index: z-index(above-base);

  &-center {
    @include paginationStyles;
  }

  &-right {
    @include paginationStylesRight;
  }

  &-limited {
    overflow-x: auto;
    scrollbar-width: none;
  }
}

.pagination {
  display: flex;
  gap: $paginationGap;
  justify-content: center;
  width: 100%;

  &--full {
    position: $paginationFullPosition;
    bottom: 0;
  }

  &__item {
    padding: $paginationItemPadding;
    cursor: pointer;

    &:before {
      display: block;
      width: $paginationItemWidth;
      height: $paginationItemHeight;
      content: '';
      background-color: $paginationItemBackground;
      border: $paginationItemBorder;
      border-radius: 50%;
    }

    &--selected {
      &:before {
        width: $paginationSelectedItemWidth;
        background-color: $paginationSelectedItemBackground;
        border-radius: $paginationSelectedItemBorderRadius;
      }
    }
  }

  &__button {
    position: absolute;
    width: 50%;
    height: 100%;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
</style>
