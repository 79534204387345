import { getState } from '@leon-hub/api-sdk';

import type { GetVipFastTrackStateDocument } from 'web/src/modules/landings/submodules/vip/types/VipFastTrackDocuments';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function getStateData(): Promise<GetVipFastTrackStateDocument> {
  const apiClient = useGraphqlClient();

  return getState(
    apiClient,
    (node) => node.queries.vipFastTrack.getState,
    { options: {} },
  );
}
