import { defineStore } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useRouter } from 'vue-router';

import type { ApiError } from '@leon-hub/api';
import type {
  CancelRequestedWithdrawalsResponse,
  CompanyFee,
  CompanyFeeRequest,
  PaymentType,
} from '@leon-hub/api-sdk';
import {
  doCancelRequestedWithdrawals,
  doSubmitWithdrawal,
  getCompanyFee as fetchCompanyFee,
  getWithdrawalsSystemInfo,
  getWithdrawalSystemsList,
  PinCodePlace,
  PinCodeRequiredExceptionCode,
} from '@leon-hub/api-sdk';
import { FormControlType, FormFieldTouchedStrategy } from '@leon-hub/form-utils';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { FormExternalErrors } from 'web/src/components/Form/types';
import type { ErrorCodeWithdrawal } from 'web/src/modules/payments/enums';
import type { PaymentFormSchema, PaymentsSubmitPayload } from 'web/src/modules/payments/types/documentTypes';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useCardNumberError } from 'web/src/modules/payments/composables/useCardNumberError';
import useCreditCardTokenization from 'web/src/modules/payments/composables/useCreditCardTokenization';
import { usePaymentsCategories } from 'web/src/modules/payments/composables/usePaymentsCategories';
import { usePaymentSystemData } from 'web/src/modules/payments/composables/usePaymentSystemData';
import { usePaymentSystemRequestTime } from 'web/src/modules/payments/composables/usePaymentSystemRequestTime';
import {
  PaymentFormFieldName,
  PaymentsFormStep,
  PaymentsStatus,
  PaymentsSubmitAction,
} from 'web/src/modules/payments/enums';
import { isErrorWithdrawalCode } from 'web/src/modules/payments/guards/isErrorWithdrawalCode';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import addCustomerFormPaymentsErrors from 'web/src/utils/forms/addCustomerFormPaymentsErrors';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import { getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';

const useWithdrawalStore = defineStore('withdrawal', () => {
  const gqlClient = useGraphqlClient();
  const analytics = useAnalytics();
  const router = useRouter();
  const { convertToBaseError } = useErrorsConverter();
  const formatMoney = useFormatMoney();

  const customerFinanceStore = useCustomerFinanceStore();
  const pinCodeStore = usePinCodeStore();
  const paymentStore = usePaymentsStore();

  const {
    setBalance,
  } = customerFinanceStore;

  const hideCommissionHint = ref(false);
  const submitData = shallowRef<Maybe<PaymentsSubmitPayload>>(null);
  const companyFee = shallowRef<Maybe<CompanyFee>>(null);

  const {
    doTokenize,
    resetToken,
    tokenizePayload,
  } = useCreditCardTokenization();

  const {
    paymentSystems,
    setCategories,
    categories,
  } = usePaymentsCategories<PaymentType.WITHDRAWAL>();

  const {
    isReadyToRequest,
    setLastPaymentSystemRequestTime,
  } = usePaymentSystemRequestTime();

  const {
    currentPaymentSystem,
    submitPaymentResponse,
    setSubmitResponse,
    paymentSystemData,
    name,
    isAdditionalRegistrationNeeded,
    creditCards,
    nextSteps,
    paymentsProcedureStatus,
    paymentMessage,
    redirectUrl,
    htmlContent,
    htmlContentProperties,
    redirectCharset,
    redirectFormParams,
    redirectMethod,
    uiFormSchema,
    formStep,
    uiFormSchamAdapted,
    initialModalProperties,
    submitButton,
    amount,
    amountAsNumber,
    amountAsMoney,
    clearCurrentStep,
    cancel,
    creditCardToken,
    validatorErrorPatterns,
    isFormPending,
    paymentSystemId,
    submittedAmountAsMoney,
    clearNDFLLocalStorageData,
    getPaymentSubmitCancelPayload,
    clearSubmitResponse,
    setSubmittedAmount,
    handlePaymentError,
    latestPaymentId,
    setPaymentId,
    customErrors,
    isCurrentValueFromSuggestedAmounts,
    cardHolder,
  } = usePaymentSystemData<PaymentType.WITHDRAWAL>();

  const availableForWithdraw = computed(() => {
    if (!currentPaymentSystem.value?.availableForWithdraw) {
      return '';
    }
    return formatMoney(currentPaymentSystem.value.availableForWithdraw);
  });

  const { cardNumberErrors } = useCardNumberError(
    [PaymentFormFieldName.CARDNUMBER_WITHDRAWAL, PaymentFormFieldName.CARDNUMBER],
    creditCardToken,
  );

  const errorCode = ref<Maybe<ErrorCodeWithdrawal | null>>(null);
  const errorMessage = ref<Maybe<string>>(null);

  async function getCompanyFee(data: CompanyFeeRequest): Promise<void> {
    const result = await fetchCompanyFee(gqlClient, (node) => node.queries.finance.getCompanyFee, {
      options: {
        amount: data.amount,
        paymentSystemId: data.paymentSystemId,
      },
    });

    companyFee.value = result;
  }

  async function loadWithdrawalSystem(systemId: string): Promise<void> {
    const data = await getWithdrawalsSystemInfo(useGraphqlClient(), (node) => node.queries.finance.withdrawal.getWithdrawalSystemInfo, { options: { paymentSystemId: systemId } });
    currentPaymentSystem.value = data;
    hideCommissionHint.value = !!data.hideCommissionHint;
  }

  async function loadWithdrawalSystems(): Promise<void> {
    if (isReadyToRequest()) {
      const data = await getWithdrawalSystemsList(gqlClient, (node) => node.queries.finance.withdrawal.getWithdrawalSystemsList, { options: {} });

      hideCommissionHint.value = !!data.hideCommissionHint;
      setCategories(data.paymentCategories);
      if (data.errorCode && data.message) {
        errorCode.value = isErrorWithdrawalCode(data.errorCode) ? data.errorCode : null;
        errorMessage.value = data.message;
      } else {
        errorCode.value = null;
        errorMessage.value = null;
      }
      setLastPaymentSystemRequestTime();
    }
  }

  async function cancelRequestedWithdrawals(): Promise<CancelRequestedWithdrawalsResponse> {
    return doCancelRequestedWithdrawals(useGraphqlClient(), (node) => node.mutations.finance.withdrawal.cancelRequestedWithdrawals, { options: {} }).catch((error: ApiError) => {
      paymentStore.errorHandler(error);
      throw error;
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function submitWithdrawal(data: PaymentsSubmitPayload): Promise<void> {
    isFormPending.value = true;
    paymentsProcedureStatus.value = { status: PaymentsStatus.LOADING, action: data.action };

    try {
      await doTokenize(data.payload, creditCards.value);
      const response = await doSubmitWithdrawal(gqlClient, (node) => node.mutations.finance.withdrawal.submit, { options: tokenizePayload.value }, {
        timeout: 60_000,
        retry: 0,
      });
      const { balance, balanceTimestamp, paymentId } = response;
      if (balance && balanceTimestamp) {
        setBalance(balance, balanceTimestamp);
      }
      if (paymentId) {
        setPaymentId(paymentId);
      }
      setSubmittedAmount();
      setSubmitResponse(response);

      if (response?.msg && response.uiFormSchema === null && !response.redirectUrl) {
        analytics.push(AnalyticsEvent.Z_WITHDRAWAL_REQUEST, {
          withdrawals: {
            ok: {
              paymentSystemId: data.payload.paymentSystemId,
            },
          },
        });
      }
    } catch (rawError) {
      const error = convertToBaseError(rawError);
      let formErrors: Maybe<FormExternalErrors> = null;

      if (error.code.equals('DISABLED_ERROR')) {
        void router.push({ name: RouteName.WITHDRAWALS });
        return;
      }

      if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
        pinCodeStore.setPinCodePlace(PinCodePlace.WITHDRAWAL);
        pinCodeStore.setStepWithLS('VERIFY');
        throw rawError;
      }

      if (uiFormSchema.value) {
        formErrors = addCustomerFormPaymentsErrors(error, uiFormSchema.value, data.payload);
      }

      // to remove - data.action === PaymentsSubmitAction.NEXT_STEP_SUBMIT
      if (data.action === PaymentsSubmitAction.NEXT_STEP_SUBMIT || data.action === PaymentsSubmitAction.SUBMIT) {
        useAnalytics().push(AnalyticsEvent.Z_WITHDRAWAL_REQUEST, {
          withdrawals: {
            error: {
              paymentSystemId: data.payload.paymentSystemId,
              message: error.message,
              code: `${error.code}`,
            },
          },
        });
      }

      if (formErrors) {
        throw new CustomerFormError(formErrors);
      }

      if (paymentsProcedureStatus.value.action) {
        paymentsProcedureStatus.value.status = PaymentsStatus.ERROR;
      }
      throw rawError;
    } finally {
      resetToken();
      isFormPending.value = false;
    }
  }

  const formProps = computed<Maybe<PaymentFormSchema>>(() => {
    if (uiFormSchamAdapted.value) {
      uiFormSchamAdapted.value
        .updateOptionsByWidget(FormControlType.FastSum, {
          availableForWithdraw: availableForWithdraw.value,
          isCryptoWithdrawal: paymentSystemData.value?.isCrypto,
        })
        .addPropertiesByWidget(FormControlType.CardExpirationDate, {
          touchedStrategy: FormFieldTouchedStrategy.Change,
        })
        .addPropertiesByWidget(FormControlType.FastSum, (field) => ({
          options: {
            ...(field.options ? getUiFieldRawOptions(field.options) : {}),
            isCurrentValueFromSuggestedAmounts: isCurrentValueFromSuggestedAmounts.value,
          },
        }));
      return {
        schema: uiFormSchamAdapted.value?.getFormSchema(),
        uiSchema: {
          ...uiFormSchamAdapted.value?.getFormUiSchema(),
          submitButton: submitButton.value,
          validatorErrorPatterns: validatorErrorPatterns.value,
        },
      };
    }
    return null;
  });

  function pushOnSuccess(): void {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      void router.push({ name: RouteName.HOME });
    } else {
      void router.closeModal();
    }
  }

  const nettoAmount = computed(() => submitPaymentResponse.value?.nettoAmount);

  const bruttoAmount = computed(() => submitPaymentResponse.value?.bruttoAmount);

  const taxAmount = computed(() => submitPaymentResponse.value?.taxAmount);

  const balanceAfterWithdrawal = computed(() => submitPaymentResponse.value?.balance);

  function setSubmitData(data: Maybe<PaymentsSubmitPayload>): void {
    submitData.value = data;
  }

  const withdrawalCustomErrors = computed(() => ({
    ...customErrors.value,
    ...((formStep.value === PaymentsFormStep.NEW_CARD || formStep.value === PaymentsFormStep.SECOND) ? cardNumberErrors.value : {}),
  }));

  function setCardHolder(value: string): void {
    cardHolder.value = value;
  }

  return {
    paymentSystemData,
    errorCode,
    errorMessage,
    loadWithdrawalSystem,
    loadWithdrawalSystems,
    withdrawalCategories: categories,
    submitWithdrawal,
    getCompanyFee,
    uiFormSchamAdapted,
    paymentSystems,
    submitData,
    setSubmitData,
    latestPaymentId,
    setPaymentId,
    cancel,
    formProps,
    formStep,
    paymentMessage,
    initialModalProperties,
    redirectUrl,
    htmlContent,
    htmlContentProperties,
    redirectCharset,
    redirectFormParams,
    redirectMethod,
    amount,
    amountAsMoney,
    amountAsNumber,
    companyFee,
    hideCommissionHint,
    isAdditionalRegistrationNeeded,
    nextSteps,
    name,
    clearCurrentStep,
    pushOnSuccess,
    taxAmount,
    bruttoAmount,
    nettoAmount,
    creditCardToken,
    isFormPending,
    paymentSystemId,
    submittedAmountAsMoney,
    clearNDFLLocalStorageData,
    getPaymentSubmitCancelPayload,
    cancelRequestedWithdrawals,
    clearSubmitResponse,
    balanceAfterWithdrawal,
    handlePaymentError,
    customErrors: withdrawalCustomErrors,
    setCardHolder,
  };
});

export default useWithdrawalStore;
