import type { Ref } from 'vue';
import { onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type { ShareSuccessLogPayload } from '../../slip-share/types';
import { useShareEventLogging } from '../../../composable';
import { useSlipInfoStore } from '../../slip-info/store';
import { useSlipShareStore } from '../store';

interface UseSlipShareRoutePage {
  bookingCode: Ref<string | null>;
  imageUrl: Ref<string | null>;
  shareLink: Ref<string | null>;
  amountSwitcherIsVisible: Ref<boolean>;
  showAmountEnabled: Ref<boolean>;
  isSharingPlacedBet: Ref<boolean>;
  isPending: Ref<boolean>;
  errorMessage: Ref<string | null>;
  closeModal(): void;
  canselShare(): void;
  onShowAmountClick(value: boolean): void;
  handleLogShareSuccess(): void;
  handleLogLinkCopied(): void;
  setLogData(): void;
}

export function useSlipShareRoutePage(): UseSlipShareRoutePage {
  const slipInfoStore = useSlipInfoStore();
  const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
  const allOdds = toRef(slipInfoStore, 'allOdds');
  const betMode = toRef(slipInfoStore, 'betMode');

  const router = useRouter();

  const slipShareStore = useSlipShareStore();

  const { canselShare, regenerateSharePicture } = slipShareStore;

  const bookingCode = toRef(slipShareStore, 'bookingCode');

  const imageUrl = toRef(slipShareStore, 'imageUrl');

  const shareLink = toRef(slipShareStore, 'shareLink');

  const shareModalShown = toRef(slipShareStore, 'shareModalShown');

  const isSlipShareEnabled = toRef(slipShareStore, 'isSlipShareEnabled');

  const amountSwitcherIsVisible = toRef(slipShareStore, 'amountSwitcherIsVisible');

  const showAmountEnabled = toRef(slipShareStore, 'showAmountEnabled');

  const isSharingPlacedBet = toRef(slipShareStore, 'isSharingPlacedBet');

  const isPending = toRef(slipShareStore, 'isPending');

  const errorMessage = toRef(slipShareStore, 'errorMessage');

  const closeModal = (): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.back();
    } else {
      void router.closeModal();
    }
  };

  let logPayload: ShareSuccessLogPayload | null = null;

  onMounted(() => {
    if (!shareModalShown.value || !isSlipShareEnabled.value) {
      closeModal();
    }
  });

  const setLogData = (): void => {
    logPayload = {
      shareCode: bookingCode.value ?? '',
      eventsCount: slipEventsCount.value,
      odds: allOdds.value.join(' | '),
      betMode: betMode.value,
    };
  };

  const onShowAmountClick = (needToShow: boolean): void => {
    regenerateSharePicture(needToShow);
  };

  const { logShareSuccess, logLinkCopied } = useShareEventLogging();

  const handleLogShareSuccess = (): void => {
    if (logPayload) {
      logShareSuccess(logPayload);
    }
  };

  const handleLogLinkCopied = (): void => {
    if (logPayload) {
      logLinkCopied(logPayload);
    }
  };

  return {
    bookingCode,
    imageUrl,
    shareLink,
    amountSwitcherIsVisible,
    showAmountEnabled,
    isSharingPlacedBet,
    isPending,
    errorMessage,
    closeModal,
    canselShare,
    onShowAmountClick,
    handleLogShareSuccess,
    handleLogLinkCopied,
    setLogData,
  };
}
