import type { Ref } from 'vue';
import {
  computed,
  ref,
  shallowRef,
  toRef,
} from 'vue';
import { useRoute } from 'vue-router';

import { GqlApiCaptchaRequiredError } from '@leon-hub/api';
import type {
  CaptchaType,
  CustomerDataFieldsFragment,
  CustomerRegistrationType,
  DoRegistrationReturnType,
  Maybe,
  PhoneCodeMethod,
  RawEntryInput,
  SimpleRegistrationInput,
  StringEntry,
  UIField,
} from '@leon-hub/api-sdk';
import {
  CustomerFieldType,
  doRegistration,
  InvalidPhoneExceptionCode,
  RegistrationStateExceptionCode,
} from '@leon-hub/api-sdk';
import { AbstractError } from '@leon-hub/errors';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { FormControlType } from '@leon-hub/form-utils';
import {
  assert,
  isBoolean,
  isObject,
  isString,
  isUndefined,
} from '@leon-hub/guards';
import { ButtonKind } from '@leon-hub/module-buttons';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import { ModalWidth } from '@leon-hub/module-modal';

import { isFormPhoneValue } from 'web/src/components/Form/guards';
import type {
  FormData,
  FormDataMap,
  FormDataValue,
  FormExternalErrors,
  FormOutput,
  FormPhoneValue,
  FormUiSchemaDefaultFields,
} from 'web/src/components/Form/types';
import { useAuthStore } from 'web/src/modules/auth/store';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
import type { RecaptchaV3Props } from 'web/src/modules/captcha/composables/useRecaptchaV3Props';
import { useRecaptchaV3Props } from 'web/src/modules/captcha/composables/useRecaptchaV3Props';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import type { CaptchaSettingsForStrategy } from 'web/src/modules/captcha/store/types';
import { isCaptchaToken } from 'web/src/modules/captcha/store/utils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import useI18n from 'web/src/modules/i18n/composables/useI18n';
import DeviceCustomerLoginStorage from 'web/src/modules/identity/utils/deviceCustomerLoginStorage';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useRegistrationAnalytics, useRegistrationFormService } from 'web/src/modules/registration/composables';
import {
  CustomCustomerFieldType,
  RegistrationApiErrorCode,
  RegistrationFieldName,
} from 'web/src/modules/registration/enums';
import { isRegistrationFormFilledFields } from 'web/src/modules/registration/guards/isRegistrationFormFilledFields';
import type {
  UseAvailableRegistrationComposable,
} from 'web/src/modules/registration/store/composables/useAvailableRegistrationForms';
import {
  useAvailableRegistrationForms,
} from 'web/src/modules/registration/store/composables/useAvailableRegistrationForms';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import type {
  CurrentRegistrationForm,
  PrepareRegistrationInputOptions,
  RegistrationForm,
  RegistrationFormFilledFields,
} from 'web/src/modules/registration/types';
import hasAnyFormErrors from 'web/src/modules/registration/utils/hasAnyFormErrors';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
import { saveCordovaRegData } from 'web/src/modules/registration/utils/saveCordovaRegData';
import useThemeStore from 'web/src/modules/theme/store/useThemeStore';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';
import mapToObject from 'web/src/utils/map/mapToObject';
import { phoneNumberStarModifier } from 'web/src/utils/phoneNumberUtils/phoneNumberStarModifier';

import { useRegistrationGeneratedPassword } from './useRegistrationGeneratedPassword';

interface UseRegistrationFormsComposable extends UseAvailableRegistrationComposable {
  registrationForm: Ref<Maybe<RegistrationForm>>;
  formFilledFields: Ref<RegistrationFormFilledFields>;
  captchaSettings: Ref<CaptchaSettingsForStrategy>;
  currentFormProperties: Ref<Optional<CurrentRegistrationForm>>;
  registrationStep: Ref<string>;
  saveFormFilledFields(formData: FormDataMap): void;
  submittedPhone: Ref<Partial<FormPhoneValue>>;
  bonusCode: Ref<Maybe<string>>;
  isFormPending: Ref<boolean>;
  regFormCustomErrors: Ref<FormExternalErrors>;
  setRegFormCustomErrors(errors: FormExternalErrors): void;
  setFormPendingStatus(value?: boolean): void;
  setCurrentForm(value: Maybe<RegistrationForm>): void;
  resetRegistrationState(): Promise<void>;
  onSubmitHandler(data: FormOutput, options: PrepareRegistrationInputOptions): Promise<void>;
  onInputHandler(data: FormOutput): void;
  onResendSmsCode(data: FormOutput, options: PrepareRegistrationInputOptions, phoneCodeMethod: PhoneCodeMethod): Promise<void>;
  setSubmittedPhone(value: FormDataValue): void;
  resetCaptchaToken(): void;
  backSchemaId: Ref<string>;
  backFormParams: Ref<StringEntry[]>;
  configuredCaptchaUiSchema: Ref<FormUiSchemaDefaultFields>;
  registrationProgress: Ref<number>;
  prevFormData: Ref<FormData>;
  submittedPhoneStarred: Ref<string>;
  generatedPassword: Ref<string>;
  submitResult: Ref<string>;
  lastRegFormParams: Ref<RawEntryInput[]>;
  setInitialAvailableForm(): void;
  hasCustomErrors: Ref<boolean>;
  lastRequestedForm: Ref<Maybe<RegistrationForm>>;
  isRegistrationCaptchaV3Enabled: Ref<boolean>;
  getConfiguredCaptchaV3UiSchema(props: RecaptchaV3Props): Ref<FormUiSchemaDefaultFields>;
}

export function useRegistrationForms(): UseRegistrationFormsComposable {
  const route = useRoute();
  const gqlClient = useGraphqlClient();
  const regFormService = useRegistrationFormService();
  const availableForms = useAvailableRegistrationForms();
  const siteConfigStore = useSiteConfigStore();
  const { captchaSettingsForStrategy } = useCaptchaStore();
  const customerDataStore = useCustomerDataStore();
  const { $translate } = useI18n();
  const { setCurrency } = useCurrencyStore();
  const { showDialog } = useDialogs();
  const regAnalytics = useRegistrationAnalytics();
  const errorConverter = useErrorsConverter();
  const eventBus = useEventsBus();
  const {
    setCaptchaToken,
    resolveCaptcha,
  } = useCaptchaResolver();

  const {
    isRegistrationCaptchaV3Enabled,
    configuredCaptchaV3UiSchema,
  } = useRecaptchaV3Props({ action: 'reg_submit' });

  const { setLoggedIn } = useAuthStore();
  const captchaSettings = captchaSettingsForStrategy(CaptchaRequesterStrategy.REGISTRATION);
  const generatedPassword = useRegistrationGeneratedPassword();

  const isRegistrationComplete = useRegistrationComplete();
  const isFormPending = ref(false);
  const isAdditionalRegistrationNeeded = ref(false);
  const bonusCode = ref<Maybe<string>>(null);
  const currentRegistrationForm = shallowRef<Maybe<RegistrationForm>>(null);
  const lastRequestedForm = shallowRef<Maybe<RegistrationForm>>(null);
  const formFilledFields = shallowRef<RegistrationFormFilledFields>({});
  const submittedPhone = shallowRef<Partial<FormPhoneValue>>({});
  const regFormCustomErrors = shallowRef<FormExternalErrors>({});
  const previousFormData = shallowRef<FormData>({});
  const submitResult = ref('');

  const formsEmailHintsEnabled = toRef(() => siteConfigStore.formsEmailHintsEnabled);
  const formsEmailHints = toRef(() => siteConfigStore.formsEmailHints);

  const themeStore = useThemeStore();
  const firstTheme = toRef(() => themeStore.firstTheme);
  const theme = toRef(() => themeStore.theme);

  eventBus.on(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, callBackEmmiter);
  eventBus.on(BusEvent.CAPTCHA_SERVICE_ERROR, callBackEmmiter);

  let captchaToken = '';

  const urlBonusCode = computed(() => (route.query.bcode ? `${route.query.bcode}` : null));

  async function resetRegistrationState(): Promise<void> {
    resetCaptchaToken();
    formFilledFields.value = {};
    regFormCustomErrors.value = {};
    bonusCode.value = null;
    generatedPassword.set('');
    submitResult.value = '';
    isAdditionalRegistrationNeeded.value = false;
    setSelectedRegistrationType(null);
    setFormPendingStatus(false);
    await fetchForms();
  }

  function setSelectedRegistrationType(registrationType: Maybe<CustomerRegistrationType>): void {
    availableForms.setSelectedRegistrationType(registrationType);
  }

  function setRegFormCustomErrors(errors: FormExternalErrors): void {
    regFormCustomErrors.value = errors;
  }

  function setGeneratedPassword(password: string): void {
    generatedPassword.set(password);
  }

  function saveFormFilledFields(formData: FormDataMap): void {
    const rawData = mapToObject(formData);
    const filteredData: RegistrationFormFilledFields = {};
    for (const key of Object.keys(rawData)) {
      if (!isUndefined(rawData[key])) {
        filteredData[key] = rawData[key];
      }
    }
    assert(isRegistrationFormFilledFields(filteredData), 'unexpected formData');
    formFilledFields.value = { ...formFilledFields.value, ...filteredData };
  }

  const registrationStep = toRef(() => currentRegistrationForm.value?.formStep ?? '');

  const registrationProgress = toRef(() => currentRegistrationForm.value?.formProgress ?? 0);

  const captchaEnabled = toRef(() => captchaSettings.value.isEnabledToShow);

  const backSchemaId = toRef(() => currentRegistrationForm.value?.goBack?.schemaId ?? '');

  const backFormParameters = toRef<() => StringEntry[]>(() => currentRegistrationForm.value?.goBack?.formParams ?? []);

  const hasCustomErrors = computed(() => Object.keys(regFormCustomErrors.value).length > 0);

  async function fetchForms(): Promise<void> {
    await availableForms.fetchForms();
    const { selectedForm } = availableForms;
    setCurrentForm(selectedForm.value);
  }

  function setInitialAvailableForm(): void {
    const { selectedForm } = availableForms;
    setCurrentForm(selectedForm.value);
  }

  async function onResendSmsCode(data: FormOutput, options: PrepareRegistrationInputOptions, phoneCodeMethod: PhoneCodeMethod): Promise<void> {
    if (hasAnyFormErrors(data)) {
      return;
    }
    await handleCaptchaVerification(data);

    await handleRegistrationRequest(data, {
      ...options,
      addFingerprint: false,
      extraFormParams: [{
        key: 'phoneVerificationType',
        value: phoneCodeMethod,
      }],
    });
  }

  async function onSubmitHandler(data: FormOutput, options: PrepareRegistrationInputOptions): Promise<void> {
    if (hasAnyFormErrors(data)) {
      return;
    }
    const { formData } = data;

    await handleCaptchaVerification(data);

    handleBonusCode(formData);

    regAnalytics.registrationSubmit();

    setRegFormCustomErrors({});

    await handleRegistrationRequest(data, options);

    if (formData[CustomerFieldType.PHONE_INPUT] && !hasCustomErrors.value) {
      setSubmittedPhone(formData[CustomerFieldType.PHONE_INPUT]);
    }
  }

  const configuredCaptchaUiSchema = toRef<() => FormUiSchemaDefaultFields>(() => ({
    [CustomCustomerFieldType.CAPTCHA_TYPE]: {
      default: captchaSettings.value.captchaType,
      widget: FormControlType.Hidden,
    },
    [CustomCustomerFieldType.CAPTCHA_TOKEN]: {
      options: {
        captchaRequesterStrategy: CaptchaRequesterStrategy.REGISTRATION,
        captchaEnabled: captchaEnabled.value,
        reCaptchaTheme: captchaSettings.value.theme,
        isEnforced: captchaSettings.value.isEnforced,
      },
      hidden: true,
      widget: FormControlType.Captcha,
    },
  }));

  const currentFormProperties = computed<Optional<CurrentRegistrationForm>>(() => {
    const form = currentRegistrationForm.value;
    if (!form?.formName) {
      return undefined;
    }
    const formSchema = regFormService.getFormSchema(form, {
      formFilledFields: formFilledFields.value,
      formsEmailHintsEnabled: formsEmailHintsEnabled.value,
      formsEmailHints: formsEmailHints.value,
      bonusCode: bonusCode.value ?? urlBonusCode.value,
    });
    if (formSchema.schema && isRegistrationCaptchaV3Enabled.value && !formSchema.schema.properties[CustomCustomerFieldType.CAPTCHA_TOKEN_V3]) {
      formSchema.schema.properties[CustomCustomerFieldType.CAPTCHA_TOKEN_V3] = { type: 'string' };
    }
    const isCaptchaFields = CustomCustomerFieldType.CAPTCHA_TYPE in (formSchema?.uiSchema?.fields ?? {});
    return {
      priority: form.priority,
      formName: form.formName,
      schemaId: form.schemaId,
      schema: formSchema.schema,
      uiSchema: {
        ...formSchema.uiSchema,
        fields: {
          ...formSchema.uiSchema.fields,
          ...(isCaptchaFields ? configuredCaptchaUiSchema.value : {}),
          ...(isRegistrationCaptchaV3Enabled.value ? configuredCaptchaV3UiSchema.value : {}),
        },
      },
    };
  });

  function callBackEmmiter(): void {
    setFormPendingStatus(false);
  }

  function setFormPendingStatus(value = false): void {
    if (isAdditionalRegistrationNeeded.value) {
      isFormPending.value = isAdditionalRegistrationNeeded.value;
      return;
    }
    isFormPending.value = value;
  }

  function filterUiFormOrderFields(fields: string[]): string[] {
    return fields.filter((field) => {
      if (field === CustomCustomerFieldType.CAPTCHA_TOKEN_V3) {
        return isRegistrationCaptchaV3Enabled.value;
      }

      return field !== 'resendSmsStepModifier';
    });
  }

  function filterUiFormFields(fields: UIField[]): UIField[] {
    return fields.filter((field) => {
      if (field.id === CustomCustomerFieldType.CAPTCHA_TOKEN_V3) {
        return isRegistrationCaptchaV3Enabled.value;
      }

      return field.id !== 'resendSmsStepModifier';
    });
  }

  function setCurrentForm(value: Maybe<RegistrationForm>): void {
    if (value === null) {
      currentRegistrationForm.value = null;
    } else {
      const normalizedForm = regFormService.normalizeFormData(value);
      if (normalizedForm) {
        currentRegistrationForm.value = {
          ...normalizedForm,
          uiFormSchema: {
            ...normalizedForm.uiFormSchema,
            order: filterUiFormOrderFields(normalizedForm.uiFormSchema.order),
            fields: filterUiFormFields(normalizedForm.uiFormSchema.fields),
          },
        };
      }
    }
  }

  function setSubmittedPhone(value: FormDataValue): void {
    const phone = isFormPhoneValue(value) ? value : { prefix: '', suffix: '' };
    submittedPhone.value = phone;
  }

  function resetCaptchaToken(): void {
    captchaToken = '';
  }

  async function handleCaptcha(
    forcedCaptchaType?: Maybe<CaptchaType>,
  ): Promise<void> {
    const captchaType = forcedCaptchaType ?? captchaSettings.value.captchaType;

    captchaToken = await resolveCaptcha(captchaType);
  }

  function onInputHandler(data: FormOutput): void {
    const token = data.formData[CustomCustomerFieldType.CAPTCHA_TOKEN];
    if (isCaptchaToken(token)) {
      setCaptchaToken(token);
    }
  }

  function handleBonusCode(formData: FormData): void {
    if (formData[CustomerFieldType.BONUS_CODE]) {
      const formBonusCode = formData[CustomerFieldType.BONUS_CODE];
      assert(isString(formBonusCode), 'bonusCode should be a string');
      bonusCode.value = formBonusCode;
      regAnalytics.setMetrikaBonusCode({ registrationType: availableForms.registrationType.value, bonusCode: formBonusCode });
    }
  }

  async function handleCaptchaVerification(data: FormOutput): Promise<void> {
    const { formData } = data;
    if (captchaSettings.value.isEnforced && !captchaToken) {
      await handleCaptcha();
    }

    if (captchaToken) {
      formData[CustomCustomerFieldType.CAPTCHA_TOKEN] = captchaToken;
    }
  }

  async function handleRegistrationRequest(data: FormOutput, options: PrepareRegistrationInputOptions): Promise<void> {
    const { formData } = data;
    let registrationInput: Maybe<SimpleRegistrationInput> = null;

    try {
      setFormPendingStatus(true);
      registrationInput = await regFormService.prepareRegistrationInput(formData, options);
      await makeRegistrationRequest(registrationInput, formData);
    } catch (error) {
      await handleRegistrationError(error, data, registrationInput);
    } finally {
      setFormPendingStatus(false);
    }
  }

  async function makeRegistrationRequest(payload: SimpleRegistrationInput, formData: FormData): Promise<void> {
    const response = await doRegistration(gqlClient, (node) => node.mutations.registration.submit, {
      options: {
        simpleRegistration: payload,
      },
    });
    handleResponse(response, formData);
  }

  async function handleRegistrationError(error: unknown, data: FormOutput, registrationInput: Maybe<SimpleRegistrationInput>): Promise<void> {
    const { formData } = data;
    if (error instanceof GqlApiCaptchaRequiredError) {
      await handleCaptcha(error.extensions.captchaType);
      if (captchaToken) {
        formData[CustomCustomerFieldType.CAPTCHA_TOKEN] = captchaToken;
      }
      if (registrationInput) {
        await makeRegistrationRequest(registrationInput, formData);
        return;
      }
    }

    regAnalytics.registrationError(
      errorConverter.convertToBaseError(error),
      registrationTypeFormat(availableForms.registrationType.value),
    );

    if (error instanceof AbstractError) {
      if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) {
        setInitialAvailableForm();
      } else if (error.code.equals(InvalidPhoneExceptionCode.INVALID_PHONE) && formData) {
        setRegFormCustomErrors(updateFieldErrorByFormError(
          formData,
          CustomerFieldType.PHONE_INPUT,
          undefined,
          regFormCustomErrors.value,
          error.message,
        ));
        return;
      } else if (error.code.equals(RegistrationApiErrorCode.TOO_MANY_ATTEMPTS)) {
        showDialog({
          presetName: PresetName.ALERT_ERROR,
          options: {
            title: $translate('WEB2_MODALTITLE_ATTENTION').value,
            analyticsModalType: undefined,
            confirmMessage: error.message,
            width: ModalWidth.SMALL,
            buttons: [
              {
                kind: ButtonKind.PRIMARY,
                action: DialogAction.MODAL_CLOSE,
                label: $translate('JSP_PCL_FBOT_CLOSE').value,
              },
            ],
          },
        });
        return;
      }
    }

    throw error;
  }

  function handleResponse(response: DoRegistrationReturnType, formData: FormData): void {
    if ((process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED || process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) && response?.form === null) {
      setCurrentForm(null);
    } else if (response?.form) {
      setCurrentForm(response?.form);
    }

    lastRequestedForm.value = response?.form ?? null;
    setGeneratedPassword(response?.generatedPassword ?? '');
    handleCustomerData(response?.customerData, formData);
    submitResult.value = response?.result ?? '';
    if (response?.form?.formError || response?.form?.hasFieldsErrors) {
      setRegFormCustomErrors(
        extractCustomerFormErrors(response?.form?.uiFormSchema, formData),
      );
    }
  }

  function handleCustomerData(customerData: Optional<Maybe<CustomerDataFieldsFragment>>, formData: FormData): void {
    if (customerData) {
      updateCustomerData(customerData);
      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        const password = formData[CustomerFieldType.PASSWORD];
        const login = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? formData[CustomerFieldType.EMAIL]
          : customerData?.customerLogin ?? '';
        if (isString(password) && isString(login)) {
          saveCordovaRegData({
            password,
            login,
          });
        }
      }
    } else {
      updatePreviousFormData(formData);
    }
  }

  function updateCustomerData(customerData: CustomerDataFieldsFragment): void {
    customerDataStore.setCustomerData(customerData);
    setLoggedIn(true);
    isRegistrationComplete.set(true);
    eventBus.off(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, callBackEmmiter);
    eventBus.off(BusEvent.CAPTCHA_SERVICE_ERROR, callBackEmmiter);

    regAnalytics.registrationSuccess({
      userId: customerData?.customerLogin ?? '',
      firstTheme: firstTheme.value,
      theme: theme.value,
      registrationType: availableForms.registrationType.value,
    });

    if (customerData.additionalRegistrationNeeded) {
      isAdditionalRegistrationNeeded.value = true;
    }

    const { currency } = customerData;
    if (isString(currency)) {
      setCurrency(currency);
    }
    DeviceCustomerLoginStorage.setLogin(customerData?.customerLogin || '');
  }

  function updatePreviousFormData(formData: FormData): void {
    previousFormData.value = (RegistrationFieldName.SMS_CODE in formData)
      ? {
          ...previousFormData.value,
          [CustomerFieldType.PHONE_INPUT]: formData[CustomerFieldType.PHONE_INPUT],
        }
      : formData;
  }

  const submittedPhoneStarred = computed<string>(() => {
    if (!submittedPhone.value) {
      return '';
    }
    return phoneNumberStarModifier({
      prefix: submittedPhone.value?.prefix ?? '',
      suffix: submittedPhone.value?.suffix ?? '',
    });
  });

  const lastRegFormParameters = computed<RawEntryInput[]>(() => {
    const currentUiSchema = currentRegistrationForm.value?.uiFormSchema;
    const fields = currentUiSchema?.fields;
    if (!fields?.length) {
      return [];
    }

    return [...fields
      .filter((field) => field.id !== RegistrationFieldName.SMS_CODE as string)
      .map<RawEntryInput>((field) => ({
        key: field.id,

        value: isObject(field.defaultValue)
          ? field.defaultValue
          : ((isBoolean(field.defaultValue))
              ? field.defaultValue
              : String(field.defaultValue)),
      })), { key: CustomCustomerFieldType.CAPTCHA_TYPE, value: captchaSettings.value.captchaType }];
  });

  function getConfiguredCaptchaV3UiSchema(props: RecaptchaV3Props): Ref<FormUiSchemaDefaultFields> {
    return useRecaptchaV3Props(props).configuredCaptchaV3UiSchema;
  }

  return {
    ...availableForms,
    registrationForm: currentRegistrationForm,
    currentFormProperties,
    registrationStep,
    captchaSettings,
    formFilledFields,
    submittedPhone,
    isFormPending,
    regFormCustomErrors,
    bonusCode,
    backSchemaId,
    backFormParams: backFormParameters,
    setRegFormCustomErrors,
    saveFormFilledFields,
    fetchForms,
    setFormPendingStatus,
    setCurrentForm,
    resetRegistrationState,
    configuredCaptchaUiSchema,
    onSubmitHandler,
    onInputHandler,
    onResendSmsCode,
    registrationProgress,
    submittedPhoneStarred,
    prevFormData: previousFormData,
    setSubmittedPhone,
    generatedPassword: toRef(generatedPassword, 'value'),
    submitResult,
    lastRegFormParams: lastRegFormParameters,
    resetCaptchaToken,
    setInitialAvailableForm,
    setSelectedRegistrationType,
    lastRequestedForm,
    hasCustomErrors,
    isRegistrationCaptchaV3Enabled,
    getConfiguredCaptchaV3UiSchema,
  };
}
