<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import type { VipFastTrackState } from '@leon-hub/api-sdk';
import {
  depositStatusDepositPending,
  depositStatusDepositSuccess,
  depositStatusNeedsDeposit,
  depositStatusUnavailable,
  verificationStatusNeedsVerif,
  verificationStatusVerified,
  verificationStatusVerifPending,
  vipFastTrackStatusAvailable,
  vipFastTrackStatusNotLoggedIn,
  vipFastTrackStatusVip,
} from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { IconName } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import type {
  LandingHeroPanelScreenProps,
} from 'web/src/modules/landings/system/components/LandingHeroPanelScreen/types';
import type { LandingSectionHeaderProps } from 'web/src/modules/landings/system/components/LandingSections/types';
import type { LandingStepProps } from 'web/src/modules/landings/system/components/LandingStep/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useI18n } from 'web/src/modules/i18n/composables';
import getStateData from 'web/src/modules/landings/submodules/vip/utils/api/getState';
import { buttonKind } from 'web/src/modules/landings/system/components/LandingButton/types';
import LandingHeroPanelScreen
  from 'web/src/modules/landings/system/components/LandingHeroPanelScreen/LandingHeroPanelScreen.vue';
import { BannerType } from 'web/src/modules/landings/system/components/LandingHeroPanelScreen/types';
import { StatusProgressType } from 'web/src/modules/landings/system/components/LandingStep/types';
import { LandingStyleAnimation, LandingStylePreset } from 'web/src/modules/landings/system/types';
import useGlobalLandingClass from 'web/src/modules/landings/useGlobalLandingClass';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { VerificationRouteType } from 'web/src/modules/profile/submodules/verification/enums';
import { useTheme } from 'web/src/modules/theme/composables';

const {
  isDark,
} = useTheme();

const userData = ref<VipFastTrackState>();

const { subscribeAuthorized } = useWebSockets();
const { isLoggedIn } = useIsLoggedIn();
const { isFastTrackEnabled } = useWebSocketsConfig();
const { $translate } = useI18n();
const formatMoney = useFormatMoney();
const currencyStore = useCurrencyStore();
const router = useRouter();
const bus = useEventsBus();
const socketPollingTimeout = ref(5000);

const { presetClass, animationClass } = useGlobalLandingClass(LandingStylePreset.PRESET_2, LandingStyleAnimation.NO_ANIMATION);

const isVip = computed(() => userData.value?.status === vipFastTrackStatusVip);

async function fetchUserData(): Promise<void> {
  userData.value = await getStateData();
}

function subscribeOnUpdatedUserState(): void {
  subscribeAuthorized({
    method: 'onVipFastTrackStateChangeEvent',
    onMessage: (data): void => {
      if (data?.onVipFastTrackStateChangeEvent?.state) {
        const newState = data.onVipFastTrackStateChangeEvent.state as unknown as VipFastTrackState;
        userData.value = {
          ...newState,
        };
      }
    },
    isEnabled: isFastTrackEnabled,
    polling: {
      timeout: socketPollingTimeout,
      callback: async () => fetchUserData(),
      callOnLogin: true,
    },
  });
}

watch(() => isLoggedIn.value, async () => {
  await fetchUserData();
});

onMounted(async (): Promise<void> => {
  await fetchUserData();
  if (isLoggedIn.value) {
    subscribeOnUpdatedUserState();
  }
});

/* eslint-disable ts/no-require-imports */

const bannerMobileImage = computed(() => (
  isDark.value
    ? require('web/src/modules/landings/submodules/vip/images/dark/main-mobile-dark.png')
    : require('web/src/modules/landings/submodules/vip/images/light/main-mobile-light.png')
));

const bannerDeskImage = computed(() => (
  isDark.value
    ? require('web/src/modules/landings/submodules/vip/images/dark/main_desktop.webp')
    : require('web/src/modules/landings/submodules/vip/images/light/main_desktop.webp')
));

const formattedAmount = computed<Record<string, string>>(() => ({ amount: userData.value?.depositAmount
  ? formatMoney(userData.value?.depositAmount, {
      currency: userData.value?.depositCurrency || currencyStore.currency,
    })
  : '' }));

const bannerVipImg = computed(() => require('web/src/modules/landings/submodules/vip/images/dark/vip-final-img.png'));

const lastBanner: ComputedRef<LandingHeroPanelScreenProps> = computed(() => {
  return {
    headerData: {
      title: $translate('WEB2_LANDING_VIP_FASTTRACK_YOU_ARE_A_VIP_TITLE').value,
      chevronLabel: $translate('WEB2_LANDING_VIP_FASTTRACK_CONGRATS_CHEVRON_LABEL').value,
      label: $translate('WEB2_LANDING_VIP_FASTTRACK_WELCOME_LABEL').value,
    },
    imageSrcMobile: bannerVipImg,
    imageSrcDesktop: bannerVipImg,
    footerData: {
      firstButtonProps: {
        label: $translate('WEB2_LANDING_VIP_FASTTRACK_DEPOSIT_LABEL').value,
        kind: buttonKind.PRIMARY,
        action: (): void => void router.push({ name: RouteName.DEPOSITS }),
      },
      secondButtonProps: {
        label: $translate('WEB2_LANDING_VIP_FASTTRACK_READ_MORE_LABEL').value,
        kind: buttonKind.QUATERNARY,
        action: (): void => bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, { id: 'landing_faq', inModal: false, smooth: true }),
      },
      isFullWidthButton: true,
    },
  };
});

const DefaultHeaderData: ComputedRef<LandingSectionHeaderProps> = computed(() => {
  return {
    chevronLabel: $translate('WEB2_LANDING_VIP_FASTTRACK_ENJOY_EXCLUSIVE').value,
    title: $translate('WEB2_LANDING_VIP_FASTTRACK_WELCOME_TITLE').value,
    label: $translate('WEB2_LANDING_VIP_FASTTRACK_SPECIAL_PERKS_LABEL').value,
    isTitleBorderedAccent: true,
  };
});

const HeroPanelScreenData: ComputedRef<LandingHeroPanelScreenProps> = computed(() => {
  return {
    headerData: DefaultHeaderData.value,
    imageSrcMobile: bannerMobileImage,
    imageSrcDesktop: bannerDeskImage,
    footerData: null
      ? {
          firstButtonProps: {
            label: $translate('WEB2_LANDING_VIP_FASTTRACK_BECOME_A_VIP_LABEL').value,
            kind: buttonKind.PRIMARY,
            action: (): void => bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, { id: 'landing_progress', inModal: false, smooth: true }),
          },
          secondButtonProps: {
            label: $translate('WEB2_LANDING_VIP_FASTTRACK_READ_MORE_LABEL').value,
            kind: buttonKind.QUATERNARY,
            action: (): void => bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, { id: 'landing_faq', inModal: false, smooth: true }),
          },
        }
      : {
          firstButtonProps: {
            label: $translate('WEB2_LANDING_VIP_FASTTRACK_READ_MORE_LABEL').value,
            kind: buttonKind.QUATERNARY,
            action: (): void => bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, { id: 'landing_faq', inModal: false, smooth: true }),
          },
        },
  };
});

const DefaultProgressHeader: ComputedRef<LandingSectionHeaderProps> = computed(() => {
  return {
    title: $translate('WEB2_LANDING_VIP_FASTTRACK_EXPRESS_CLUB_TITLE').value,
    label: $translate('WEB2_LANDING_VIP_FASTTRACK_FOLLOW_STEPS_LABEL').value,
  };
});

const progressFooterData = computed(() => {
  const { value: user } = userData;
  if (!user || user.status === vipFastTrackStatusNotLoggedIn) {
    return {
      firstButtonProps: {
        label: $translate('WEB2_LANDING_VIP_FASTTRACK_LOGIN_LABEL').value,
        kind: buttonKind.SECONDARY,
        action: (): void => void router.push({ name: RouteName.LOGIN }),
      },
      secondButtonProps: {
        label: $translate('WEB2_LANDING_VIP_FASTTRACK_REGISTRATION_LABEL').value,
        kind: buttonKind.TERTIARY,
        action: (): void => void router.push({ name: RouteName.REGISTRATION }),
      },
    };
  }

  if (user.status === vipFastTrackStatusAvailable) {
    if (user.verificationStatus === verificationStatusNeedsVerif) {
      return {
        firstButtonProps: {
          label: $translate('WEB2_LANDING_VIP_FASTTRACK_VERIFICATION_LABEL').value,
          kind: buttonKind.SECONDARY,
          action: (): void => void router.push({
            name: RouteName.CUSTOMER_VERIFICATION,
            params: { type: VerificationRouteType.VIP },
          }),
        },
      };
    }

    if (user.depositStatus === depositStatusNeedsDeposit && user.verificationStatus === verificationStatusVerified) {
      return {
        firstButtonProps: {
          label: $translate('WEB2_LANDING_VIP_FASTTRACK_DEPOSIT_LABEL').value,
          kind: buttonKind.SECONDARY,
          action: (): void => void router.push({ name: RouteName.DEPOSITS }),
        },
      };
    }

    if (
      (user.depositStatus === depositStatusUnavailable || user.depositStatus === depositStatusNeedsDeposit)
      && user.verificationStatus === verificationStatusVerifPending
    ) {
      return {
        paragraph: {
          label: $translate('WEB2_LANDING_VIP_FASTTRACK_VERIFICATION_PROCESS_LABEL').value,
          icon: IconName.TIMER_SAND,
        },
      };
    }

    if (
      (user.depositStatus === depositStatusDepositPending || user.depositStatus === depositStatusDepositSuccess)
      && (user.verificationStatus === verificationStatusVerified || user.verificationStatus === verificationStatusVerifPending)
    ) {
      return {
        paragraph: {
          label: $translate('WEB2_LANDING_VIP_FASTTRACK_WAITING_STATUS_LABEL').value,
          icon: IconName.TIMER_SAND,
        },
      };
    }
  }

  return undefined;
});

const DefaultProgressList: ComputedRef<LandingStepProps[]> = computed(() => [
  {
    numberLabel: '1',
    type: StatusProgressType.DEFAULT,
    title: $translate('WEB2_LANDING_VIP_FASTTRACK_SIGN_IN_TITLE').value,
    label: $translate('WEB2_LANDING_VIP_FASTTRACK_LOGIN_LABEL').value,
  },
  {
    numberLabel: '2',
    type: StatusProgressType.DEFAULT,
    title: $translate('WEB2_LANDING_VIP_FASTTRACK_COMPLETE_VERIFICATION_TITLE').value,
    label: $translate('WEB2_LANDING_VIP_FASTTRACK_VERIFY_IDENTITY_LABEL').value,
  },
  {
    numberLabel: '3',
    type: StatusProgressType.DEFAULT,
    title: $translate('WEB2_LANDING_VIP_FASTTRACK_MAKE_A_DEPOSIT_TITLE').value,
    label: $translate('WEB2_LANDING_VIP_FASTTRACK_FUND_ACCOUNT_LABEL', formattedAmount).value,
  },
]);

const bannerProps: ComputedRef<LandingHeroPanelScreenProps> = computed(() => ({
  ...HeroPanelScreenData.value,
  headerData: DefaultHeaderData.value,
  progressList: {
    list: DefaultProgressList.value.map((step, index) => {
      const type = (() => {
        switch (index) {
          case 0:
            return userData.value?.status === vipFastTrackStatusAvailable
              ? StatusProgressType.COMPLETE
              : StatusProgressType.DEFAULT;

          case 1:
            switch (userData.value?.verificationStatus) {
              case verificationStatusVerified:
                return StatusProgressType.COMPLETE;
              case verificationStatusVerifPending:
                return StatusProgressType.IN_PROGRESS;
              default:
                return StatusProgressType.DEFAULT;
            }

          case 2:
            switch (userData.value?.depositStatus) {
              case depositStatusDepositSuccess:
                return StatusProgressType.COMPLETE;
              case depositStatusDepositPending:
                return StatusProgressType.IN_PROGRESS;
              default:
                return StatusProgressType.DEFAULT;
            }

          default:
            return StatusProgressType.DEFAULT;
        }
      })();

      return { ...step, type };
    }),
  },
  progressHeaderData: DefaultProgressHeader.value,
  progressFooterData: progressFooterData.value,
}));
</script>

<template>
  <div v-auto-id="'LandingVipExpressRoutePage'"
    :class="{
      [presetClass]: true,
      [animationClass]: true,
    }"
  >
    <LandingHeroPanelScreen
      v-if="isVip"
      v-bind="lastBanner"
      :type="BannerType.FULL_THIN"
    />
    <LandingHeroPanelScreen
      v-else
      v-bind="bannerProps"
      :type="BannerType.SHORT_DEFAULT"
    />
  </div>
</template>

<style lang="scss" module>
// техническая информация, в будущем удалить
.develop {
  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 30px;
    color: #fff;
  }

  @include landingDesktop {
    flex-direction: row;
  }
}
</style>
